/* eslint-disable spaced-comment */
import {
  ensureArray,
  ensureObject,
  objectNotEmpty,
  stringNotEmpty,
} from './display'
import { PRODUCTS } from './products'

/** ROLE FLAGS **/
const ROLE_ADMIN = 'admin'
const ROLE_AGENT = 'agent'

/** ELIGIBLE FLAGS **/
const ELIGIBLE_REGISTER = 'eligible_register' // Used to shut down a user or an installation from signing up
const ELIGIBLE_TRIAL = 'eligible_trial' // Used to shut down a user or an installation from continual trial signup

/** CONFIGURATION FLAGS **/
const FLAG_CONFIG_MULTIPLE = 'config_multi' // PREMIUM (PLUS ADD-ON)
const FLAG_CONFIG_APP_SETTINGS = 'config_app_settings' // TODO!!!!: ENTERPRISE (PLUS ADD-ON) - once we have config for everyone - FOR NOW THIS IS TURNED ON BY INSTALLATION ID

/** MACRO FLAGS **/
const FLAG_MACROS_SNIPPETS = 'macros_snippets' // PREMIUM (PLUS ADD-ON) // TODO!! remove the previous 'macro_snippets' from firebase after a reasonable amount of time
const FLAG_MACROS_AI = 'macros_ai' // PREMIUM (VIA ADD-ON PROVIDER)
const FLAG_MACROS_CATALOG = 'macros_catalog' // PREMIUM // TODO: removed old flag 'macros_advanced_catalog' in firebase after release 5.9.0
const FLAG_MACROS_ADVANCED = 'macros_advanced' // PREMIUM (PLUS ADD-ON)
const FLAG_MACROS_ADVANCED_AI = 'macros_advanced_ai' // PREMIUM (VIA ADD-ON PROVIDER)

/** REMINDER FLAGS **/
const FLAG_REMINDERS = 'reminders' // ENTERPRISE (PLUS ADD-ON) // TODO: use this to handle non-order reminders
const FLAG_REMINDERS_ORDER = 'reminders_order' // ENTERPRISE (PLUS ADD-ON)

/** TICKETING FLAGS **/
const FLAG_TICKET_CREATE = 'ticket_create' // PROFESSIONAL
const FLAG_TICKET_SAVE = 'ticket_save' // PREMIUM
const FLAG_TICKETING = 'ticketing' // ENTERPRISE
const FLAG_TICKETING_ADVANCED = 'ticketing_advanced' // ENTERPRISE

/** TIMELINE FLAGS **/
const FLAG_TIMELINE_FILTER = 'timeline_filter' // PREMIUM
const FLAG_TIMELINE_EVENTS_CUSTOMER = 'timeline_events_customer' // PREMIUM (requires Customer Insights)
const FLAG_TIMELINE_EVENTS_ORDER = 'timeline_events_notes' // PROFESSIONAL (CommerceProvider) // TODO - remove 'timeline_notes' from Firebase, rename 'timeline_events_notes' to 'timeline_events_order'
const FLAG_TIMELINE_EVENTS_ORDER_MODIFY = 'timeline_events_notes_modify' // PREMIUM // TODO - rename 'timeline_events_notes_modify' to 'timeline_events_order_modify'
const FLAG_TIMELINE_EVENTS_TICKET = 'timeline_events_ticket' // PROFESSIONAL
const FLAG_TIMELINE_EVENTS_ADVANCED = 'timeline_events_advanced' // PREMIUM - (SUNSHINE EVENTS ENABLED FOR CUSTOMER) // TODO - remove 'timeline_events' from Firebase

// TODO!!!!!!!: decide if any other flags???
/** INTEGRATION FLAGS (Integration Provider) */
const FLAG_INTEGRATION_METADATA = 'integration_metadata' // PREMIUM (VIA ADD-ON PROVIDER only for now)

/** CUSTOMER FLAGS (Commerce Provider) **/
const FLAG_CUSTOMER_DETAILS = 'customer_details' // STARTER
const FLAG_CUSTOMER_DETAILS_ADVANCED = 'customer_details_advanced' // PREMIUM (Customer Insights)
const FLAG_CUSTOMER_METADATA = 'customer_metadata' // ENTERPRISE (disabled for all platforms that don't support FLAG_CUSTOMER_DETAILS_ADVANCED)
const FLAG_CUSTOMER_MODIFY = 'customer_modify' // PREMIUM
const FLAG_CUSTOMER_MODIFY_DATA = 'customer_modify_data' // PREMIUM (disabled for all platforms that don't support FLAG_CUSTOMER_MODIFY)
const FLAG_CUSTOMER_MODIFY_TAGS = 'customer_modify_tags' // PREMIUM (disabled for all platforms that don't support FLAG_CUSTOMER_MODIFY)
const FLAG_CUSTOMER_MODIFY_STORE_CREDIT = 'customer_modify_store_credit' // PREMIUM (disabled for all platforms that don't support FLAG_CUSTOMER_MODIFY and Shopify)
const FLAG_CUSTOMER_MODIFY_GIFT_CARDS = 'customer_modify_gift_cards' // PREMIUM (disabled for all platforms that don't support FLAG_CUSTOMER_MODIFY)
const FLAG_CUSTOMER_VIEW_GIFT_CARDS = 'customer_gift_cards' // PREMIUM (disabled for all platforms that don't support FLAG_CUSTOMER_DETAILS_ADVANCED)
const FLAG_CUSTOMER_NOTES_ADD = 'customer_notes_add' // PREMIUM (disabled for all platforms that don't support FLAG_CUSTOMER_MODIFY)
const FLAG_CUSTOMER_NOTES_MODIFY = 'customer_notes_modify' // PREMIUM (disabled for all platforms that don't support FLAG_CUSTOMER_MODIFY) // TODO - remove 'customer_modify_notes' from Firebase
const FLAG_CUSTOMER_STORED_ADDRESSES = 'customer_stored_addresses' // ENTERPRISE (disabled for all platforms that don't support FLAG_PAYMENT_SHIPPING)
const FLAG_CUSTOMER_SAVE = 'customer_save' // PREMIUM

/** ATTRIBUTION FLAGS (Commerce Provider) **/
const FLAG_ATTRIBUTION_CART_SHARE = 'attribution_cart_share' // (ONLY CUSTOM - FOR NOW)
const FLAG_ATTRIBUTION_CART = 'attribution_cart' // ENTERPRISE (support differs by provider - up to provider to check flag and implement [CT, EP, SHOP, BC for now])

/** CART FLAGS (Commerce Provider) **/
const FLAG_CART_PROMOTION_ITEMS = 'cart_promotion_items' // ENTERPRISE (for BC + EP only for now - need to test/enable on other providers)
const FLAG_CART_PROMOTION = 'cart_promotion' // ENTERPRISE
const FLAG_CART_SHARE = 'cart_share' // ENTERPRISE
const FLAG_CART_NOTES = 'cart_notes' // ENTERPRISE (for Shopify only for now - need to test/enable on other providers)
const FLAG_CART_MODIFY_ITEMS = 'cart_modify' // ENTERPRISE // TODO!!!: rename in FB to >>> cart_modify_items
const FLAG_CART_MODIFY_ITEMS_PRICE = 'cart_modify_items_price' // ENTERPRISE (support differs by provider)
const FLAG_CART_MODIFY_CURRENCY = 'cart_modify_currency' // ENTERPRISE (for Shopify only for now - need to test/enable on other providers)
const FLAG_CART_MODIFY_SHIPPING_ITEMS_PRICE = 'cart_modify_shipping_items_price' // ENTERPRISE (EP only for now)

/** ORDER FLAGS (Commerce Provider) **/
const FLAG_ORDERS_LIST = 'orders_list' // FREE
const FLAG_ORDERS_FILTER = 'orders_filter' // STARTER
const FLAG_ORDERS_SORT = 'orders_sort' // STARTER
const FLAG_ORDERS_LOAD_GUESTS = 'orders_load_guests' // PROFESSIONAL (NOTE: can be turned off to prevent the loading of guest orders on initial load)

const FLAG_ORDER_PIN = 'order_pin' // STARTER
const FLAG_ORDER_SAVE = 'order_save' // PREMIUM (NOTE: this is the save to watchlist)

const FLAG_ORDER_DETAILS = 'order_details' // STARTER
const FLAG_ORDER_DETAILS_ADVANCED = 'order_details_advanced' // PROFESSIONAL (disabled for M1)
const FLAG_ORDER_INSIGHTS = 'order_insights' // PREMIUM
const FLAG_ORDER_METADATA = 'order_metadata' // ENTERPRISE
const FLAG_ORDER_RETURNS = 'order_returns' // ENTERPRISE

const FLAG_ORDER_CANCEL = 'order_cancel' // PREMIUM
const FLAG_ORDER_CREATE = 'order_create' // ENTERPRISE (NOTE: must have 'search_products_advanced' or 'load_products' - AND either 'order_payment' (to be renamed 'payment_capture') or 'cart_share' - otherwise automatically disabled)
const FLAG_ORDER_DUPLICATE = 'order_duplicate' // ENTERPRISE (NOTE: must have either 'order_payment' (to be renamed 'payment_capture') or 'cart_share' otherwise automatically disabled)
const FLAG_ORDER_MODIFY = 'order_modify' // ENTERPRISE
const FLAG_ORDER_MODIFY_ITEMS = 'order_modify_items' // ENTERPRISE
const FLAG_ORDER_MODIFY_ITEMS_PRICE = 'order_modify_items_price' // ENTERPRISE (only enabled for SHOP and CT so far)
const FLAG_ORDER_MODIFY_TAGS = 'order_modify_tags' // PREMIUM
const FLAG_ORDER_MODIFY_STATUS = 'order_modify_status' // PREMIUM
const FLAG_ORDER_MODIFY_ADDRESS = 'order_modify_address' // ENTERPRISE
const FLAG_ORDER_MODIFY_ADDRESS_ADVANCED = 'order_modify_address_advanced' // ENTERPRISE
const FLAG_ORDER_ADD_ITEMS = 'order_add_items' // ENTERPRISE (NOTE: must have 'order_modify_items' otherwise automatically disabled)
const FLAG_ORDER_STATUS_ADVANCED = 'order_status_advanced' // PROFESSIONAL // TODO!!!: delete old 'orders_list_advanced' and 'orders_status_advanced' from Firebase
const FLAG_ORDER_BALANCE = 'order_balance' // PREMIUM

const FLAG_ORDER_ITEM_DETAILS = 'order_item_details' // PROFESSIONAL

const FLAG_REFUND_REASON = 'refund_reason' // PREMIUM
const FLAG_REFUND_ORDER = 'order_refund' // PREMIUM
const FLAG_REFUND_PARTIAL = 'partial_refund' // ENTERPRISE
const FLAG_REFUND_CUSTOM = 'custom_refund' // ENTERPRISE
const FLAG_REFUND_ITEM = 'item_refund' // PREMIUM

/** LOAD PRODUCTS FLAGS (Commerce Provider) **/
const FLAG_LOAD_PRODUCTS = 'load_products' // ENTERPRISE (can be turned off for an installation to avoid initial load)
const FLAG_LOAD_PRODUCTS_SCROLL = 'load_products_scroll' // ENTERPRISE - TODO: implement
const FLAG_LOAD_PRODUCTS_PAGING = 'load_products_paging' // ENTERPRISE
const FLAG_LOAD_PRODUCTS_PAGING_CURSOR = 'load_products_paging_cursor' // ENTERPRISE (only enabled for Shopify right now)

/** PAYMENT FLAGS (Payment or Commerce Provider) **/
const FLAG_PAYMENT_CAPTURE_OPTIONAL = 'payment_capture_optional' // ENTERPRISE (only enabled for SHOP, EP and BC so far) // TODO: delete this FLAG_PAYMENT_CAPTURE_OPTIONAL flag from FB??
const FLAG_PAYMENT_CAPTURE = 'order_payment' // ENTERPRISE - SHOULD THIS BE 'payment_capture'?
const FLAG_PAYMENT_SHIPPING = 'payment_shipping' // ENTERPRISE
const FLAG_PAYMENT_BILLING = 'payment_billing' // ENTERPRISE
const FLAG_PAYMENT_TRANSACTIONS = 'payment_transactions' // PROFESSIONAL
const FLAG_PAYMENT_STORED_INSTRUMENTS = 'payment_stored_instruments' // ENTERPRISE

/** SHIPPING FLAGS (Shipping Provider) **/
const FLAG_SHIPPING_OPTIONS = 'shipping_options' // ENTERPRISE
const FLAG_SHIPPING_STATUS = 'shipping_status' // PROFESSIONAL
// HMMM do we also want to rename the value in FB??
const FLAG_SHIPPING_INSIGHTS = 'shipping_quotes' // PROFESSIONAL (disabled for Starter & Free, needs to be enabled by provider - ie. ShipperHQ)
const FLAG_SHIPPING_LABEL = 'shipping_label' // ENTERPRISE - TODO: implement
const FLAG_SHIPPING_ADDRESS_PHONE = 'shipping_address_phone' // ENTERPRISE (disabled for all platforms that don't support FLAG_PAYMENT_SHIPPING)

/** SEARCH FLAGS (Search or Commerce Provider?) **/
const FLAG_SEARCH_CARTS = 'search_carts' // ENTERPRISE ("cart selector" - for CT & SHOP only for now)
const FLAG_SEARCH_ORDERS = 'search_orders' // PROFESSIONAL
const FLAG_SEARCH_PRODUCTS_ADVANCED = 'search_products_advanced' // ENTERPRISE (provider specific - BC, Shopify & M2 for now) // TODO!!!: RENAME THIS FLAG to search_products
const FLAG_SEARCH_CUSTOMERS = 'search_customers' // STARTER
const FLAG_SEARCH_CUSTOMERS_ADVANCED = 'search_customers_advanced' // PROFESSIONAL
const FLAG_SEARCH_CUSTOMERS_ADVANCED_NAME = 'search_customers_advanced_name' // PROFESSIONAL
const FLAG_SEARCH_CUSTOMERS_ADVANCED_PHONE = 'search_customers_advanced_phone' // PROFESSIONAL
const FLAG_SEARCH_CUSTOMERS_ADVANCED_ID = 'search_customers_advanced_id' // PROFESSIONAL

const FLAG_SUBSCRIPTION_DELETE = 'subscription_delete'

/** TAX FLAGS (Tax Provider?) **/
const FLAG_TAX_CALCULATOR = 'tax_calculator' // ENTERPRISE - TODO: implement

/** SETTINGS/CONFIGURATION FLAGS **/
const SETTINGS_IGNORE_SHIPPING_OPTIONS = 'settings_ignore_shipping_options' // NOTE: can be used to shut off loading shipping options from Shipping Provider (to only use Dyanmic Data)
const SETTINGS_IGNORE_SUBSCRIPTION_CHECK = 'settings_ignore_subscription_check' // NOTE: can be used to shut off subscription verification (for plan of 'free_v1' it is set to true)
const SETTINGS_IGNORE_LISTING_OVERRIDES = 'settings_ignore_listing_overrides' // NOTE: for future use in case we ever want to swap someone w/ standalone app back to regular
const SETTINGS_CONFIG_TAX_INCLUDED = 'settings_config_tax_included' // NOTE: used by BC to change how we display tax
const SETTINGS_CONFIG_DATA_SHARED = 'settings_config_data_shared' // NOTE: presently only turnded on for listing: 'customer_insights' below
const SETTINGS_IGNORE_VERIFIED_INPUT = 'settings_ignore_verified_input' // NOTE: can be used to disable verified input (for Shopify Premium)

export const roles = {
  ROLE_ADMIN,
  ROLE_AGENT,
}

export const eligibility = {
  ELIGIBLE_REGISTER,
  ELIGIBLE_TRIAL,
}

export const flags = {
  FLAG_CONFIG_MULTIPLE,
  FLAG_CONFIG_APP_SETTINGS,
  FLAG_MACROS_SNIPPETS,
  FLAG_MACROS_CATALOG,
  FLAG_MACROS_AI,
  FLAG_MACROS_ADVANCED_AI,
  FLAG_MACROS_ADVANCED,
  FLAG_REMINDERS,
  FLAG_REMINDERS_ORDER,
  FLAG_TICKET_CREATE,
  FLAG_TICKET_SAVE,
  FLAG_TICKETING,
  FLAG_TICKETING_ADVANCED,
  FLAG_TIMELINE_FILTER,
  FLAG_TIMELINE_EVENTS_TICKET,
  FLAG_TIMELINE_EVENTS_ORDER,
  FLAG_TIMELINE_EVENTS_ORDER_MODIFY,
  FLAG_TIMELINE_EVENTS_ADVANCED,
  FLAG_TIMELINE_EVENTS_CUSTOMER,
  FLAG_INTEGRATION_METADATA,
  FLAG_CUSTOMER_STORED_ADDRESSES,
  FLAG_CUSTOMER_DETAILS_ADVANCED,
  FLAG_CUSTOMER_DETAILS,
  FLAG_CUSTOMER_NOTES_ADD,
  FLAG_CUSTOMER_METADATA,
  FLAG_CUSTOMER_MODIFY,
  FLAG_CUSTOMER_MODIFY_DATA,
  FLAG_CUSTOMER_MODIFY_TAGS,
  FLAG_CUSTOMER_NOTES_MODIFY,
  FLAG_CUSTOMER_MODIFY_STORE_CREDIT,
  FLAG_CUSTOMER_MODIFY_GIFT_CARDS,
  FLAG_CUSTOMER_VIEW_GIFT_CARDS,
  FLAG_CUSTOMER_SAVE,
  FLAG_ATTRIBUTION_CART,
  FLAG_ATTRIBUTION_CART_SHARE,
  FLAG_CART_PROMOTION_ITEMS,
  FLAG_CART_PROMOTION,
  FLAG_CART_SHARE,
  FLAG_CART_MODIFY_ITEMS,
  FLAG_CART_MODIFY_ITEMS_PRICE,
  FLAG_CART_MODIFY_SHIPPING_ITEMS_PRICE,
  FLAG_CART_MODIFY_CURRENCY,
  FLAG_CART_NOTES,
  FLAG_ORDERS_LOAD_GUESTS,
  FLAG_ORDERS_LIST,
  FLAG_ORDERS_FILTER,
  FLAG_ORDER_PIN,
  FLAG_ORDER_SAVE,
  FLAG_ORDER_DETAILS,
  FLAG_ORDER_DETAILS_ADVANCED,
  FLAG_ORDER_INSIGHTS,
  FLAG_ORDER_METADATA,
  FLAG_ORDER_RETURNS,
  FLAG_ORDERS_SORT,
  FLAG_ORDER_CANCEL,
  FLAG_ORDER_CREATE,
  FLAG_ORDER_DUPLICATE,
  FLAG_ORDER_MODIFY,
  FLAG_ORDER_MODIFY_ADDRESS,
  FLAG_ORDER_MODIFY_ADDRESS_ADVANCED,
  FLAG_ORDER_MODIFY_ITEMS_PRICE,
  FLAG_ORDER_MODIFY_ITEMS,
  FLAG_ORDER_MODIFY_TAGS,
  FLAG_ORDER_MODIFY_STATUS,
  FLAG_ORDER_ADD_ITEMS,
  FLAG_ORDER_STATUS_ADVANCED,
  FLAG_ORDER_BALANCE,
  FLAG_ORDER_ITEM_DETAILS,
  FLAG_REFUND_REASON,
  FLAG_REFUND_ORDER,
  FLAG_REFUND_PARTIAL,
  FLAG_REFUND_ITEM,
  FLAG_REFUND_CUSTOM,
  FLAG_LOAD_PRODUCTS,
  FLAG_LOAD_PRODUCTS_SCROLL,
  FLAG_LOAD_PRODUCTS_PAGING,
  FLAG_LOAD_PRODUCTS_PAGING_CURSOR,
  FLAG_PAYMENT_CAPTURE_OPTIONAL,
  FLAG_PAYMENT_CAPTURE,
  FLAG_PAYMENT_SHIPPING,
  FLAG_PAYMENT_BILLING,
  FLAG_PAYMENT_TRANSACTIONS,
  FLAG_PAYMENT_STORED_INSTRUMENTS,
  FLAG_SHIPPING_ADDRESS_PHONE,
  FLAG_SHIPPING_OPTIONS,
  FLAG_SHIPPING_STATUS,
  FLAG_SHIPPING_INSIGHTS,
  FLAG_SHIPPING_LABEL,
  FLAG_SEARCH_CARTS,
  FLAG_SEARCH_ORDERS,
  FLAG_SEARCH_CUSTOMERS,
  FLAG_SEARCH_CUSTOMERS_ADVANCED,
  FLAG_SEARCH_CUSTOMERS_ADVANCED_ID,
  FLAG_SEARCH_CUSTOMERS_ADVANCED_NAME,
  FLAG_SEARCH_CUSTOMERS_ADVANCED_PHONE,
  FLAG_SEARCH_PRODUCTS_ADVANCED,
  FLAG_SUBSCRIPTION_DELETE,
  FLAG_TAX_CALCULATOR,
  SETTINGS_IGNORE_SHIPPING_OPTIONS,
  SETTINGS_IGNORE_SUBSCRIPTION_CHECK,
  SETTINGS_IGNORE_LISTING_OVERRIDES,
  SETTINGS_CONFIG_TAX_INCLUDED,
  SETTINGS_CONFIG_DATA_SHARED,
  SETTINGS_IGNORE_VERIFIED_INPUT,
}

export const flags_free = [
  FLAG_ORDERS_LIST,
  SETTINGS_IGNORE_SUBSCRIPTION_CHECK,
  SETTINGS_IGNORE_LISTING_OVERRIDES
]

export const flags_starter = [
  ...flags_free,
  FLAG_CUSTOMER_DETAILS,
  FLAG_ORDERS_FILTER,
  FLAG_ORDERS_SORT,
  FLAG_ORDER_PIN,
  FLAG_ORDER_DETAILS,
  FLAG_SEARCH_CUSTOMERS,
  SETTINGS_CONFIG_TAX_INCLUDED
]

export const flags_professional = [
  ...flags_starter,
  FLAG_ORDER_ITEM_DETAILS,
  FLAG_ORDER_DETAILS_ADVANCED,
  FLAG_ORDER_STATUS_ADVANCED,
  FLAG_ORDERS_LOAD_GUESTS,
  FLAG_TIMELINE_EVENTS_ORDER,
  FLAG_TIMELINE_EVENTS_TICKET,
  FLAG_TICKET_CREATE,
  FLAG_PAYMENT_TRANSACTIONS,
  FLAG_SHIPPING_STATUS,
  FLAG_SHIPPING_INSIGHTS,
  FLAG_SEARCH_ORDERS,
  FLAG_SEARCH_CUSTOMERS_ADVANCED,
  FLAG_SEARCH_CUSTOMERS_ADVANCED_NAME,
  FLAG_SEARCH_CUSTOMERS_ADVANCED_PHONE,
  FLAG_SEARCH_CUSTOMERS_ADVANCED_ID
]

export const flags_premium = [
  ...flags_professional,
  FLAG_CONFIG_MULTIPLE,
  FLAG_ORDER_SAVE,
  FLAG_ORDER_CANCEL,
  FLAG_ORDER_BALANCE,
  FLAG_ORDER_INSIGHTS,
  FLAG_ORDER_MODIFY_TAGS,
  FLAG_ORDER_MODIFY_STATUS,
  FLAG_TIMELINE_FILTER,
  FLAG_TIMELINE_EVENTS_ORDER_MODIFY,
  FLAG_TIMELINE_EVENTS_ADVANCED,
  FLAG_TIMELINE_EVENTS_CUSTOMER,
  FLAG_INTEGRATION_METADATA,
  FLAG_CUSTOMER_DETAILS_ADVANCED,
  FLAG_CUSTOMER_NOTES_ADD,
  FLAG_CUSTOMER_NOTES_MODIFY,
  FLAG_CUSTOMER_MODIFY,
  FLAG_CUSTOMER_MODIFY_DATA,
  FLAG_CUSTOMER_MODIFY_TAGS,
  FLAG_CUSTOMER_MODIFY_STORE_CREDIT,
  FLAG_CUSTOMER_MODIFY_GIFT_CARDS,
  FLAG_CUSTOMER_VIEW_GIFT_CARDS,
  FLAG_CUSTOMER_SAVE,
  FLAG_TICKET_SAVE,
  FLAG_MACROS_CATALOG,
  FLAG_MACROS_SNIPPETS,
  FLAG_MACROS_ADVANCED,
  FLAG_MACROS_ADVANCED_AI,
  FLAG_MACROS_AI,
  FLAG_REFUND_REASON,
  FLAG_REFUND_ORDER,
  FLAG_REFUND_ITEM
]

export const flags_enterprise = Object.values(flags)

// NOTE: to enable cart collab for an agent
// cart_modify
// cart_share
// load_products_paging
// order_create
// order_duplicate
// search_products
// ---- OPTIONAL ----
// cart_promotion
// attribution_cart_share

export const TIERS = {
  [PRODUCTS.FREE]: flags_free,
  [PRODUCTS.STARTER]: flags_starter,
  [PRODUCTS.PROFESSIONAL]: flags_professional,
  [PRODUCTS.PREMIUM]: flags_premium,
  [PRODUCTS.ENTERPRISE]: flags_enterprise,
}

export const limitFlags = (_flags, { agent, installation, conditional } = {}) => {
  const {
    id: agentId,
    role: agentRole,
  } = ensureObject(agent)
  const {
    app_data: {
      auth_type,
    } = {},
    settings: {
      listing,
    } = {},
  } = ensureObject(installation)

  return {
    ...stringNotEmpty(agentId) && {
      id: agentId,
    },
    ...stringNotEmpty(agentRole) && {
      role: agentRole,
    },
    // TODO: move each of these to a helper that mutates flags as it goes to the next one to clean this up quite a bit
    ...objectNotEmpty(_flags) && {
      ..._flags,
      ...conditional,
      ...(!flags.FLAG_CART_PROMOTION) && {
        [flags.FLAG_CART_PROMOTION_ITEMS]: false,
      },
      ...(!flags.FLAG_CART_MODIFY_ITEMS_PRICE) && {
        [flags.FLAG_CART_MODIFY_SHIPPING_ITEMS_PRICE]: false,
      },
      ...(!_flags[flags.FLAG_MACROS_AI] || !_flags[flags.FLAG_MACROS_ADVANCED]) && {
        [flags.FLAG_MACROS_ADVANCED_AI]: false,
      },
      ...(!_flags[flags.FLAG_MACROS_SNIPPETS]) && {
        [flags.FLAG_MACROS_AI]: false, // TODO: decide if we want to disables ai macros if no snippets?
        [flags.FLAG_MACROS_ADVANCED]: false,
        [flags.FLAG_MACROS_ADVANCED_AI]: false,
      },
      ...(!_flags[flags.FLAG_ORDER_DETAILS_ADVANCED]) && {
        [flags.FLAG_ORDER_INSIGHTS]: false,
        [flags.FLAG_ORDER_METADATA]: false,
        [flags.FLAG_ORDER_RETURNS]: false,
      },
      ...(!_flags[flags.FLAG_ORDER_MODIFY]) && {
        [flags.FLAG_ORDER_ADD_ITEMS]: false,
        [flags.FLAG_ORDER_MODIFY_ITEMS]: false,
        [flags.FLAG_ORDER_MODIFY_ITEMS_PRICE]: false,
        [flags.FLAG_ORDER_MODIFY_ADDRESS]: false,
        [flags.FLAG_ORDER_MODIFY_ADDRESS_ADVANCED]: false,
      },
      ...(!_flags[flags.FLAG_ORDER_MODIFY_ADDRESS]) && {
        [flags.FLAG_ORDER_MODIFY_ADDRESS_ADVANCED]: false,
      },
      ...(!_flags[flags.FLAG_ORDER_MODIFY_ITEMS]) && {
        [flags.FLAG_ORDER_ADD_ITEMS]: false,
        [flags.FLAG_ORDER_MODIFY_ITEMS_PRICE]: false,
      },
      ...(!_flags[flags.FLAG_SEARCH_PRODUCTS_ADVANCED] && !flags.FLAG_LOAD_PRODUCTS) && {
        [flags.FLAG_ORDER_CREATE]: false,
      },
      ...(!flags.FLAG_LOAD_PRODUCTS) && {
        [flags.FLAG_LOAD_PRODUCTS_SCROLL]: false,
        [flags.FLAG_LOAD_PRODUCTS_PAGING]: false,
        [flags.FLAG_LOAD_PRODUCTS_PAGING_CURSOR]: false,
      },
      ...(!_flags[flags.FLAG_PAYMENT_BILLING]) && {
        [flags.FLAG_PAYMENT_CAPTURE]: false,
      },
      ...(!_flags[flags.FLAG_CART_SHARE] &&
        (
          !_flags[flags.FLAG_PAYMENT_CAPTURE] ||
          !_flags[flags.FLAG_PAYMENT_BILLING]
        )
      ) && {
        [flags.FLAG_ATTRIBUTION_CART]: false,
        [flags.FLAG_ORDER_CREATE]: false,
        [flags.FLAG_ORDER_DUPLICATE]: false,
        [flags.FLAG_SEARCH_CARTS]: false,
      },
      ...(
        !_flags[flags.FLAG_CUSTOMER_DETAILS] ||
        !_flags[flags.FLAG_CUSTOMER_DETAILS_ADVANCED] ||
        !_flags[flags.FLAG_CUSTOMER_MODIFY]
      ) && {
        [flags.FLAG_CUSTOMER_MODIFY]: false,
        [flags.FLAG_CUSTOMER_MODIFY_DATA]: false,
        [flags.FLAG_CUSTOMER_MODIFY_TAGS]: false,
        [flags.FLAG_CUSTOMER_MODIFY_GIFT_CARDS]: false,
        [flags.FLAG_CUSTOMER_MODIFY_STORE_CREDIT]: false,
        [flags.FLAG_CUSTOMER_NOTES_ADD]: false,
        [flags.FLAG_CUSTOMER_NOTES_MODIFY]: false,
      },
      ...(
        !_flags[flags.FLAG_CUSTOMER_DETAILS] ||
        !_flags[flags.FLAG_CUSTOMER_DETAILS_ADVANCED]
      ) && {
        [flags.FLAG_TIMELINE_EVENTS_CUSTOMER]: false,
        [flags.FLAG_CUSTOMER_METADATA]: false,
        [flags.FLAG_CUSTOMER_SAVE]: false,
        [flags.FLAG_CUSTOMER_VIEW_GIFT_CARDS]: false, // HMM check if we also need to add this in the case above
        // TODO: do we need to disable FLAG_CUSTOMER_MODIFY_GIFT_CARDS???
      },
      ...(!_flags[flags.FLAG_TICKETING]) && {
        [flags.FLAG_TICKETING_ADVANCED]: false,
        [flags.FLAG_TICKET_SAVE]: false,
      },
      ...(!_flags[flags.FLAG_SHIPPING_STATUS]) && {
        [flags.FLAG_SHIPPING_INSIGHTS]: false,
        [flags.FLAG_SHIPPING_LABEL]: false,
      },
      ...(!_flags[flags.FLAG_PAYMENT_SHIPPING]) && {
        [flags.FLAG_CUSTOMER_STORED_ADDRESSES]: false,
        [flags.FLAG_SHIPPING_ADDRESS_PHONE]: false,
        [flags.FLAG_SHIPPING_OPTIONS]: false,
      },
      // eslint-disable-next-line eqeqeq
      ...(auth_type === 'single') && {
        [flags.FLAG_CONFIG_MULTIPLE]: false,
      },
      // TODO/TEMP - revert!!!!
      ...(listing === 'customer_insights') && {
        [flags.SETTINGS_CONFIG_DATA_SHARED]: true,
      },
    },
  }
}

export const convertFeatureFlags = (featureFlags, defaultValue = true) => {
  const flagsArray = Array.isArray(featureFlags)
    ? featureFlags
    : Object.entries(ensureObject(featureFlags))

  return ensureArray(flagsArray).reduce((_flags, flag) => {
    // eslint-disable-next-line no-nested-ternary
    const featureFlag = (typeof flag === 'string') // TODO: what if numeric?? should this use isString?
      ? { [flag]: defaultValue }
      : Array.isArray(flag)
        ? { [flag[0]]: flag[1] }
        : flag

    return {
      ..._flags,
      ...featureFlag,
    }
  }, {})
}
