/* eslint-disable no-use-before-define */
import { DateTime, Duration, Interval, Settings } from 'luxon'

import {
  stringEmpty,
  stringNotEmpty,
  ensureNumericNegatable,
  isNumericNegatable,
  isTypeEnhanced,
  ensureObject,
} from './display'
import { templateReplace } from './data'

// TODO!!!: keep in sync between next-shopify and lib-core
// TODO: consolidate these to export as COMMON_FORMATS
export const TIME_FORMAT_LONG = 'HH:mm'
export const TIME_FORMAT_AMPM = 'h:mm a'

const DATE_FORMAT_RFC822 = 'EEE, dd LLL yyyy HH:mm:ss ZZZ'
export const DATE_FORMAT_MED = 'M/d/yyyy'
export const DATE_FORMAT_LONG = 'MM/dd/yyyy'
export const DATE_FORMAT_TRACKING = 'MMddyyyy'
export const DATE_FORMAT_ORGANIZED = 'yyyy-MM-dd'

const {
  DATE_SHORT,
  DATE_MED,
  DATE_FULL,
  DATE_HUGE,
  DATETIME_SHORT,
  DATETIME_MED,
  DATETIME_MED_WITH_WEEKDAY,
  DATETIME_MED_WITH_SECONDS,
  DATETIME_FULL,
  DATETIME_HUGE,
  TIME_SIMPLE,
} = DateTime

const DATE_MINI = {
  ...DATE_SHORT,
  year: '2-digit',
}

const DATETIME_MINI = {
  ...DATETIME_SHORT,
  year: '2-digit',
}

const {
  timeZoneName: _timeZoneNameF,
  ...DATETIME_FULL_NO_ZONE
} = DATETIME_FULL

const {
  timeZoneName: _timeZoneNameH,
  ...DATETIME_HUGE_NO_ZONE
} = DATETIME_HUGE

export const CUSTOM_FORMATS = {
  UTC: 'UTC',
  ISO: 'ISO',
  UNIX: 'UNIX',
  RFC822: 'RFC822',
}

export const LOCALE_FORMATS = {
  DATE_MINI,
  DATE_SHORT,
  DATE_MED,
  DATE_FULL,
  DATE_HUGE,
  DATETIME_MINI,
  DATETIME_SHORT,
  DATETIME_MED,
  DATETIME_MED_WITH_WEEKDAY,
  DATETIME_MED_WITH_SECONDS,
  DATETIME_FULL_NO_ZONE,
  DATETIME_HUGE_NO_ZONE,
  TIME_SIMPLE,
}

const DATE_PARTS = {
  day: 'dd',
  month: 'MM',
  year: 'yyyy',
}

const STANDARD_LOCALE_FORMATS = Object.values(LOCALE_FORMATS)

const hasSomeNumbers = (value) => /\d/.test(value)
const hasOnlyValidCharacters = (value) => /^[\w-+:,\\/\s.]+$/.test(value) // TODO: confirm if these are all needed?

export const getDaysInMonth = (year, month) => (
  new Date(year, month, 0).getDate()
)

export const setDateTimeLocale = (locale) => {
  if (stringNotEmpty(locale)) {
    Settings.defaultLocale = locale
  }
}

export const setDateTimeZone = (zone) => {
  if (stringNotEmpty(zone)) {
    Settings.defaultZone = zone
  }
}

const matchDateTimeByFormat = (input, options) => {
  if (!isTypeEnhanced(input, 'string')) {
    return undefined
  }

  let validDateTime
  // NOTE: intentionally using for/of loop instead of map so we can break to exit quickly soon as matched
  // eslint-disable-next-line no-restricted-syntax
  for (const format of [DATE_FORMAT_MED, DATE_FORMAT_LONG, DATE_FORMAT_TRACKING, DATE_FORMAT_ORGANIZED]) {
    if (input.length === format.length) {
      const dateTime = DateTime.fromFormat(input, format, options)
      if (dateTime?.isValid) {
        validDateTime = dateTime
        break
      }
    }
  }

  return validDateTime
}

export const getDateTimeFormat = ({ locale, mappings = DATE_PARTS } = {}) => {
  const localeParts = getCurrentDateTime().toLocaleParts({ locale })

  return localeParts.map(({ type, value }) => {
    if (type === 'literal') {
      return value
    }

    return mappings[type]
  }).filter(stringNotEmpty).join('')
}

export const getDateTimeFromString = ({ format, value, ...options } = {}) => {
  if (
    !hasSomeNumbers(value) ||
    !hasOnlyValidCharacters(value) ||
    (isTypeEnhanced(value, 'string') && stringEmpty(value))
  ) {
    return undefined
  }

  if (stringNotEmpty(format)) {
    return DateTime.fromFormat(value, format, options)
  }

  const matchedDateTime = matchDateTimeByFormat(value, options)
  if (matchedDateTime?.isValid) {
    return matchedDateTime
  }

  if (isNumericNegatable(value)) {
    return DateTime.fromSeconds(ensureNumericNegatable(value))
  }

  let dateTime = DateTime.fromISO(value, options)

  if (!dateTime?.isValid) {
    dateTime = DateTime.fromSQL(value, options)
  }

  if (!dateTime?.isValid) {
    dateTime = DateTime.fromRFC2822(value, options)
  }

  return dateTime
}

export const convertDateTime = (value, { shouldThrow = true, shouldLog = shouldThrow, ...options } = {}) => {
  let dateTime
  try {
    switch (true) {
      // eslint-disable-next-line eqeqeq
      case (value == undefined): {
        break
      }

      case DateTime.isDateTime(value): {
        dateTime = value
        break
      }

      case (value instanceof Date): {
        dateTime = DateTime.fromJSDate(value, options)
        break
      }

      case isTypeEnhanced(value, 'object'): {
        dateTime = DateTime.fromObject(value, options)
        break
      }

      default: {
        dateTime = getDateTimeFromString({ value, ...options })
        break
      }
    }

    if (dateTime && !dateTime.isValid) {
      if (shouldLog) {
        console.info('Issue converting timestamp', { value, dateTime })
      }
      throw new Error(['Error converting timestamp', dateTime?.invalidExplanation].filter(stringNotEmpty).join('. '))
    }
  } catch (error) {
    if (shouldThrow) {
      throw error
    }
    dateTime = undefined
  }

  return dateTime
}

export const convertDuration = (value, { shouldThrow = true, ...options } = {}) => {
  let duration
  try {
    switch (true) {
      // eslint-disable-next-line eqeqeq
      case (value == undefined): {
        break
      }

      case Duration.isDuration(value): {
        duration = value
        break
      }

      case isTypeEnhanced(value, 'object'): {
        duration = Duration.fromObject(value, options)
        break
      }

      case isNumericNegatable(value): {
        duration = Duration.fromMillis(value, options)
        break
      }

      case (
        isTypeEnhanced(value, 'string') &&
        stringNotEmpty(value) &&
        hasSomeNumbers(value) &&
        hasOnlyValidCharacters(value)
      ): {
        duration = Duration.fromISOTime(value, options)

        if (!duration?.isValid) {
          duration = Duration.fromISO(value, options)
        }

        break
      }

      default: {
        break
      }
    }

    if (duration && !duration.isValid) {
      console.error('Error converting duration', { value, duration })
      throw new Error(['Error converting duration', duration?.invalidExplanation].filter(stringNotEmpty).join('. '))
    }
  } catch (error) {
    if (shouldThrow) {
      throw error
    }
    duration = undefined
  }

  return duration
}

export const getDuration = (dt1, dt2) => (
  Interval.fromDateTimes(convertDateTime(dt1), convertDateTime(dt2)).toDuration()
)

export const getCurrentDateTime = () => (
  DateTime.now()
)

const getDateTimeDifference = (dt1, unit, dt2 = getCurrentDateTime()) => (
  dt2.diff(ensureDateTime(dt1), unit)?.[unit]
)

export const getMinutesBetween = (dt1, dt2) => (
  Math.abs(getDateTimeDifference(dt1, 'minutes', dt2))
)

export const getDaysBetween = (dt1, dt2) => (
  Math.abs(getDateTimeDifference(dt1, 'days', dt2))
)

export const getWeeksBetween = (dt1, dt2) => (
  Math.abs(getDateTimeDifference(dt1, 'weeks', dt2))
)

const isSameDateTime = (dt1, dt2 = getCurrentDateTime(), unit) => (
  // eslint-disable-next-line eqeqeq
  dt1.startOf(unit).ts == dt2.startOf(unit).ts
)

export const isSameHour = (dt1, dt2) => (
  isSameDateTime(dt1, dt2, 'hour')
)

export const isSameDay = (dt1, dt2) => (
  isSameDateTime(dt1, dt2, 'day')
)

export const ensureDateTime = (value, options) => (
  convertDateTime(value, options) ?? getCurrentDateTime()
)

export const toJSDate = (value, options) => (
  ensureDateTime(value, options).toJSDate()
)

export const toUnixInteger = (value, options) => (
  ensureDateTime(value, options).toUnixInteger()
)

// consolidate w/ toFormatted({ value, CUSTOM_FORMATS.UNIX })
export const toUnixString = (value, options) => (
  `${toUnixInteger(convertDateTime(value, options), options)}`
)

// consolidate w/ toFormatted({ value, format: CUSTOM_FORMATS.ISO })
export const toISOString = (value, options) => (
  ensureDateTime(value, options).toISO()
)

// consolidate w/ toFormatted({ value, format: CUSTOM_FORMATS.UTC })
export const toUTCString = (value, options) => (
  ensureDateTime(value, options).toUTC().toString()
)

export const toRFC822String = (value, options) => (
  toFormatted({ value, format: CUSTOM_FORMATS.RFC822, ...options })
)

// HMMM: return toString if no format (or similar)??
export const toFormatted = ({ format, value, zone, ...options } = {}) => {
  // eslint-disable-next-line eqeqeq
  if (format == undefined) {
    throw new Error('Please supply a format')
  }

  let datetime = ensureDateTime(value, options)

  if (stringNotEmpty(zone)) {
    datetime = datetime.setZone(zone)
  }

  switch (true) {
    case (format === CUSTOM_FORMATS.UTC): {
      return datetime.toUTC().toString()
    }

    case (format === CUSTOM_FORMATS.ISO): {
      return datetime.toISO()
    }

    case (format === CUSTOM_FORMATS.UNIX): {
      return `${toUnixInteger(datetime)}`
    }

    case (format === CUSTOM_FORMATS.RFC822): {
      return datetime.toFormat(DATE_FORMAT_RFC822, options)
    }

    case (isTypeEnhanced(format, 'string')): {
      return datetime.toFormat(format, options)
    }

    default: {
      if (!STANDARD_LOCALE_FORMATS.includes(format)) {
        console.warn('Please consider using a standard format from LOCALE_FORMATS')
      }

      return datetime.toLocaleString(format, options)
    }
  }
}

export const convertFormatted = ({
  value,
  to: { format: toFormat, ...toOptions } = {},
  from: { format: fromFormat, ...fromOptions } = {},
} = {}) => {
  if (stringEmpty(value)) {
    return undefined
  }

  return toFormatted({
    format: toFormat,
    value: getDateTimeFromString({ value, format: fromFormat, ...fromOptions }),
    ...toOptions,
  })
}

export const compareDate = (date1, date2) => (
  -convertDateTime(date1).diff(convertDateTime(date2)).as('milliseconds')
)

export const formatTimestamps = (timestamps, defaultValue) => (
  Object.entries(ensureObject(timestamps)).reduce((_timestamps, [name, value]) => ({
    ..._timestamps,
    [name]: value ? toUnixString(value) : defaultValue,
  }), {})
)

export const formatRequiredTimestamps = ({ created_at, updated_at, ...timestamps } = {}) => {
  const _createdTimestamp = toUnixString(created_at)
  const updatedTimestamp = updated_at ? toUnixString(updated_at) : _createdTimestamp
  const createdTimestamp = `${Math.min(_createdTimestamp, updatedTimestamp)}` // HMMM: why doing math on strings??

  const otherTimestamps = formatTimestamps(timestamps, createdTimestamp)
  return { created_at: createdTimestamp, updated_at: updatedTimestamp, ...otherTimestamps }
}

const getPrecisionTime = (() => {
  if ((typeof performance !== 'undefined') && (typeof performance.now === 'function')) {
    // NOTE: Use performance.now() if available as its the most precise (BROWSER)
    return () => performance.now()
  }

  if ((typeof process !== 'undefined') && (typeof process.hrtime === 'function')) {
    // NOTE: Use process.hrtime() if available as its the next most precise (NODE.JS - depending on version)
    const start = process.hrtime()
    const startMilliseconds = getCurrentDateTime().toMillis()
    return () => {
      const [seconds, nanoseconds] = process.hrtime(start)
      return startMilliseconds + seconds * 1000 + nanoseconds / 1e6 // Convert to a single float in milliseconds
    }
  }

  return () => getCurrentDateTime().toMillis()
})() // NOTE: intentionally self running so only executed once

// EXAMPLE: const myresult = await measure(getMyResult(), '>>> TIMING > getMyResult: {{seconds}}s')
export const measure = async (promised, log) => {
  const start = getPrecisionTime()
  const result = await promised
  const end = getPrecisionTime()
  const duration = (end - start)
  const milliseconds = duration.toFixed(3)

  switch (true) {
    case isTypeEnhanced(log, 'function'): {
      log(milliseconds)
      break
    }

    case isTypeEnhanced(log, 'string'): {
      console.log(templateReplace(log, { milliseconds, seconds: (duration / 1000).toFixed(3) }))
      break
    }

    default: {
      console.log(`EXECUTE AND MEASURE: ${milliseconds}ms`)
      break
    }
  }

  return result
}
