// TODO!!!: consolidate w/ lib-utils & lib-core
import { flags } from './flags' // HMMM: will this cause a circular reference??

export const CART_NEW = 'cart_new'
export const CART_DRAFT = 'cart_draft'
export const PROVIDER_SUFFIX = 'Provider'
export const I18N_MISSING_PREFIX = '##MISSING: '
export const METADATA_PROP = '_meta'
export const ELLIPSE = '...'

export const BATCH_SIZES = {
  QUEUE: 5,
  DEFAULT: 10,
  PROVIDERS: 20,
  PROVIDER_TYPES: 20,
  PROVIDER_SETS: 12,
}

export const SHARED_HEADERS = {
  INSTALLATION_ID: 'X-Installation-Id',
  STACK_ID: 'X-Stack-Id',
  APP_ID: 'X-App-Id',
  APP_VERSION: 'X-App-Version',
  APP_LOCATION: 'X-App-Location',
}

export const ATTRIBUTION_FIELD = {
  AGENT_ID: 'agent_id',
  TICKET_ID: 'ticket_id',
  ORDER_ID: 'original_order_id',
}

export const JUSTIFICATION_FIELD = {
  NOTE: 'note',
  AUDIT: 'audit',
  REASON: 'reason',
}

export const ATTRIBUTION_FIELD_KEYS = Object.values(ATTRIBUTION_FIELD)

export const PAYMENT_METHOD = {
  GIFT_CARD: 'gift_card',
  OFFLINE: 'offline',
}

export const MACRO_ACTIONS = {
  SAVE: 'save',
  SAVENEW: 'save-new',
  DELETE: 'delete',
}

export const COLLECTIONS = {
  USAGE_TRACKING: 'usage-tracking',
  FEATURE_FLAGS: 'feature-flags',
  APP_CONFIG: 'app-config',
  APP_DATA: 'app-data',
  AUDIT: 'audit',
  MACROS: 'macros',
  REMINDERS: 'reminders',
  NOTIFICATIONS: 'notifications',
  DEFAULT: 'DEFAULT',
}

export const COLLECTION_TYPES = {
  GLOBAL: 'global',
  GROUP: 'group',
  USER: 'user',
  INSTALLATION: 'installation',
}

export const DOCUMENT_TYPES = {
  DATA: 'data',
}

export const DATA_TYPES = {
  AI_COMMANDS: 'ai-commands',
  AI_PROMPTS: 'ai-prompts',
  SAVED_ORDERS: 'saved-orders',
  SAVED_TICKETS: 'saved-tickets',
  SAVED_CUSTOMERS: 'saved-customers',
  OVERRIDE_CONFIG: 'override-config',
  DYNAMIC_CONFIG: 'dynamic-config',
  SUBSCRIPTIONS: 'subscriptions',
  MACROS: COLLECTIONS.MACROS, // NOTE: still in legacy FB location
  REMINDERS: COLLECTIONS.REMINDERS, // NOTE: still in legacy FB location
  NOTIFICATIONS: COLLECTIONS.NOTIFICATIONS, // NOTE: still in legacy FB location
}

export const AI_COMMAND_TYPES = {
  INSTANT: 'instant',
  MACRO: 'macro',
}

export const AI_PROMPT_TYPES = {
  REPLY_PROMPT: 'reply_prompt',
  MACRO_PROMPT: 'macro_prompt',
}

export const DYNAMIC_CONFIG_TYPES = {
  AUDIT_MESSAGE: 'audit_message',
  CART_COLLAB_MESSAGE: 'cart_collab_message',
  CUSTOMER_PRODUCT_IMAGE: 'customer_product_image',
  CUSTOMER_PRODUCT_LINK: 'customer_product_link',
  EXTERNAL_PRODUCT_LINK: 'external_product_link',
  CUSTOMER_ORDER_LINK: 'customer_order_link',
  EXTERNAL_ORDER_ITEM_LINK: 'external_order_item_link',
  EXTERNAL_ORDER_LINK: 'external_order_link',
  DUPLICATE_ORDER_LINK: 'duplicate_order_link',
  NEW_ORDER_LINK: 'new_order_link',
  NEW_TICKET_SUBJECT: 'new_ticket_subject',
  SHIPPING_OPTIONS: 'shipping_options',
  REFUND_REASONS: 'refund_reasons',
  SUBSCRIPTION_REASONS: 'subscription_reasons',
}

export const COLLECTION_TYPES_MAPPINGS = {
  [COLLECTION_TYPES.USER]: ({ userId }) => userId,
  [COLLECTION_TYPES.INSTALLATION]: ({ installationId }) => installationId,
}

// TODO: update to support multiple collections per mapping (and/or default to global)??
export const DATA_TYPES_MAPPINGS = {
  [DATA_TYPES.AI_COMMANDS]: {
    collection: COLLECTION_TYPES.GLOBAL,
    flags: flags.FLAG_MACROS_AI,
  },
  [DATA_TYPES.AI_PROMPTS]: {
    collection: COLLECTION_TYPES.GLOBAL,
    flags: flags.FLAG_MACROS_ADVANCED_AI,
  },
  [DATA_TYPES.SAVED_ORDERS]: {
    collection: COLLECTION_TYPES.USER,
    flags: flags.FLAG_ORDER_SAVE,
  },
  [DATA_TYPES.SAVED_TICKETS]: {
    collection: COLLECTION_TYPES.USER,
    flags: flags.FLAG_TICKET_SAVE,
  },
  [DATA_TYPES.SAVED_CUSTOMERS]: {
    collection: COLLECTION_TYPES.USER,
    flags: flags.FLAG_CUSTOMER_SAVE,
  },
  [DATA_TYPES.OVERRIDE_CONFIG]: {
    collection: COLLECTION_TYPES.INSTALLATION,
    // TODO: explore if flags needed here??
  },
  [DATA_TYPES.DYNAMIC_CONFIG]: {
    collection: COLLECTION_TYPES.INSTALLATION,
    // TODO: explore if flags needed here??
  },
  [DATA_TYPES.SUBSCRIPTIONS]: {
    collection: COLLECTION_TYPES.INSTALLATION,
  },
  [DATA_TYPES.MACROS]: {
    collection: COLLECTION_TYPES.INSTALLATION,
    flags: flags.FLAG_MACROS_ADVANCED,
  },
  [DATA_TYPES.REMINDERS]: {
    collection: COLLECTION_TYPES.INSTALLATION,
    flags: [flags.FLAG_REMINDERS, flags.FLAG_REMINDERS_ORDER],
  },
  [DATA_TYPES.NOTIFICATIONS]: {
    collection: COLLECTION_TYPES.INSTALLATION,
    // TODO: explore if flags needed here??
  },
}

export const OVERRIDE_TYPES = {
  MAX_PROVIDER_SETS: 'provider_sets_max',
}

export const CUSTOMER_TYPES = {
  GUEST: 'guest',
  CUSTOMER: 'customer',
}

export const CONFIGURATION_VIEWS = {
  CORE: 'core',
  COMBINED: 'combined',
  ADD_ONS: 'addOns',
}

export const INSIGHT_TYPES = {
  SHIPPING: 'shipping',
  CUSTOMER: 'customer',
  ORDER: 'order',
}

export const VALID_DATA_TYPES = Object.values(DATA_TYPES)
export const VALID_COLLECTION_TYPES = [...new Set(Object.values(DATA_TYPES_MAPPINGS).map(({ collection }) => collection))]

export const TOKEN_PREFIX = 'token_'

export const SETTING_PREFIX = '{{setting.'
export const SETTING_SUFFIX = '}}'

export const MAX_PROVIDER_SETS_ENCRYPT = 5

// TODO do we need to handle en vs en-US?
export const DEFAULT_LOCALE = 'en'
export const DEFAULT_LOCALE_COUNTRY = `${DEFAULT_LOCALE}-US`

// TODO: update all providers/front end to use PRIMARY_STATUS constant for transformed types
export const PRIMARY_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
}

export const VALIDATION_STATUS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export const SEARCH_FILTERS_AGNOSTACK = {
  STATUS: 'status',
  COUNT: 'count',
  SKU: 'sku',
  NAME: 'name',
  KEYWORDS: 'keywords',
  VISIBILE: 'visible',
  VISIBILITY: 'visibilty',
}

export const ZIS_CLIENTS = {
  SHOPIFY: 'shopify',
  ZENDESK: 'zendesk',
}

export const PRICE_MODE = {
  CUSTOM: 'custom',
  STANDARD: 'standard',
}

export const TAX_MODE = {
  LINE_ITEM: 'line_item',
  TOTAL: 'total',
}

export const MESSAGE_STATUS = {
  READ: 'read',
  UNREAD: 'unread',
}

export const TICKET_STATUS = {
  NEW: 'new',
  OPEN: 'open',
  HOLD: 'hold',
  PENDING: 'pending',
  CLOSED: 'closed',
  SOLVED: 'solved',
}
