import {
  ensureArray,
  ensureString,
  stringEmpty,
  stringNotEmpty,
  objectNotEmpty,
  objectEmpty,
} from './display'
import { getTranslated } from './translations'
import { DEFAULT_LOCALE, I18N_MISSING_PREFIX } from './constants'

// HMMMM: why dont we use mustache (or handlebars) here?
const parsePlaceholders = (str, data = {}) => {
  const regex = /{{(.*?)}}/g
  const matches = []
  let match

  do {
    match = regex.exec(str)
    if (match) {
      matches.push(match)
    }
  } while (match)

  return matches.reduce((_str, _match) => {
    const newRegex = new RegExp(_match[0], 'g')
    return _str.replace(newRegex, data[_match[1]] || '')
  }, str)
}

// TODO: consolidate w/ utils-js
// eslint-disable-next-line no-restricted-globals, no-undef
const traverse = (selector, obj = self, separator = '.') => {
  const properties = Array.isArray(selector)
    ? selector
    : ensureString(selector).split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

const singleton = Symbol('singleton')
const singletonEnforcer = Symbol('singletonEnforcer')

export class I18n {
  constructor(enforcer, locale, localeLoader = getTranslated) {
    this.type = 'I18n'
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot instantiate I18n directly, please use getInstance()')
    }

    this._localeLoader = localeLoader
    this.defaultLocale = DEFAULT_LOCALE
    this.locale = locale
    this.translations = {}
  }

  static async getInstance (locale, localeLoader) {
    if (!this[singleton]) {
      this[singleton] = new I18n(singletonEnforcer, locale, localeLoader)
    }

    if (
      (stringEmpty(locale) && !this[singleton].isInitialized) ||
      (stringNotEmpty(locale) && (objectEmpty(this[singleton].translations[locale]) || (locale !== this[singleton].locale)))
    ) {
      await this[singleton].setLocale(locale)
    }

    return this[singleton]
  }

  // TODO: revisit method signature for last 3 optional params (and add support to pass in specific locale???)
  t(key, data, fallbackKey, fallback) {
    const keyType = typeof key
    // TODO: move to isType helper
    if (keyType !== 'string') {
      throw new Error(`Translation key must be a string, got: ${keyType}`)
    }

    let template = traverse(key, this.translations[this.locale])
    if (!template && this.locale !== this.defaultLocale) {
      console.log(`Missing translation[${this.locale}]: ${key}`)
      template = traverse(key, this.translations[this.defaultLocale]) // try default locale as backup
    }

    if (!template) {
      if (!fallbackKey) {
        console.warn(`Missing translation: ${key}`)
      }

      // TODO: revisit method signature for last 3 optional params
      // eslint-disable-next-line no-nested-ternary
      template = fallbackKey
        ? this.t(fallbackKey, data, undefined, fallback)
        // eslint-disable-next-line eqeqeq
        : (fallback != undefined)
          ? fallback
          : `${I18N_MISSING_PREFIX}${key}`
    }

    const templateType = typeof template
    if (templateType !== 'string') {
      throw new Error(`Translation template must be a string, got: ${templateType}`)
    }

    return parsePlaceholders(template, data)
  }

  get isInitialized() {
    return objectNotEmpty(this.translations)
  }

  async localeLoader (locale) {
    if (objectEmpty(this.translations[locale])) {
      const translated = await this._localeLoader(locale)

      if (stringNotEmpty(locale)) {
        this.translations[locale] = translated
      } else {
        this.translations = translated
      }
    }

    return stringNotEmpty(locale) ? this.translations[locale] : this.translations
  }

  async loadTranslations(locale) {
    if (stringEmpty(locale)) {
      if (!this.isInitialized) {
        const translations = await this.localeLoader()
        this.translations = translations
      }

      return this.translations
    }

    if (objectNotEmpty(this.translations[locale])) {
      return this.translations[locale]
    }

    const _localeTranslations = await Promise.all([
      this.localeLoader(locale),
      this.localeLoader(locale.replace(/-.+$/, '')),
      this.localeLoader(this.defaultLocale)
    ])

    const localeTranslations = ensureArray(_localeTranslations).find((localeTranslation) => objectNotEmpty(localeTranslation))
    if (objectNotEmpty(localeTranslations)) {
      this.translations[locale] = localeTranslations
    }

    return localeTranslations
  }

  getLocale() {
    return this.locale
  }

  async setLocale(locale) {
    if (locale !== this.locale) {
      this.locale = locale
    }

    return this.loadTranslations(this.locale)
  }

  async getTranslations (locale) {
    try {
      if (stringEmpty(locale) && stringEmpty(this.locale)) {
        const allTranslations = await this.loadTranslations()
        return allTranslations
      }

      const tLocale = [locale, this.locale].find((_tLocale) => stringNotEmpty(_tLocale))
      const localizedTranslations = await this.loadTranslations(tLocale)

      if (!this.locale && stringNotEmpty(locale)) {
        this.locale = locale
      }

      return localizedTranslations
    } catch (_ignore) {
      return undefined
    }
  }
}
