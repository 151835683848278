import { capitalize } from './display'

export const SUPPORT_PLATFORMS = {
  // SALESFORCE: 'salesforce',
  // FRESHDESK: 'freshdesk',
  ZENDESK: 'zendesk',
  STANDALONE: 'standalone',
}

// TODO: consolidate w/ pricing.json (lib-core/pricingData)
export const PRODUCTS = {
  FREE: 'free',
  STARTER: 'starter',
  PROFESSIONAL: 'professional',
  PREMIUM: 'premium',
  ENTERPRISE: 'enterprise',
}

// TODO: investigate why this setup
// TODO: move these to object???
export const PLAN_FREE = `${PRODUCTS.FREE}_v1`
export const PLAN_STARTER = `${PRODUCTS.STARTER}_v1`
export const PLAN_PROFESSIONAL = `${PRODUCTS.PROFESSIONAL}_v1`
export const PLAN_PREMIUM = `${PRODUCTS.PREMIUM}_v1`
export const PLAN_ENTERPRISE = `${PRODUCTS.ENTERPRISE}_v3`

// TODO: consolidate w/ pricing.json (lib-core/pricingData)
export const PRODUCT_NAMES = {
  [PLAN_FREE]: capitalize(PRODUCTS.FREE),
  [PLAN_STARTER]: capitalize(PRODUCTS.STARTER),
  [PLAN_PROFESSIONAL]: capitalize(PRODUCTS.PROFESSIONAL),
  [PLAN_PREMIUM]: capitalize(PRODUCTS.PREMIUM),
  [PLAN_ENTERPRISE]: capitalize(PRODUCTS.ENTERPRISE),
}

// TODO!!!: clean up products vs plans
export const getSubscriptionProduct = (subscription) => ({
  name: PRODUCT_NAMES[subscription?.plan?.nickname],
})
