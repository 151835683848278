import {
  ensureString,
  cleanObject,
  objectEmpty,
  stringEmpty,
  objectNotEmpty,
  stringEmptyOnly,
} from './display'
import { SUBSCRIPTION_METADATA } from './subscription'

const PROMO_SEPARATOR = '~'

const FAUX_DISCOUNT_CODES = [
  'ACH',
  'TRIAL',
  'PARTNER'
]

// TODO: move to external PRM management system
const PARTNER_DISCOUNT_MAPPING = {
  ZENDESK: {
    discount_code: 'ZDPREMIUM', // 30% for 12 mos
  },
  LAPLANTE: {
    discount_code: 'ZDPREMIUM', // 30% for 12 mos
  },
  SUCCESSCX: {
    discount_code: 'REFERRAL1524', // (15% for first 24 months) // TODO should this be “SUCCESSCX30”?
  },
  EVOLUTION: {
    discount_code: 'REFERRAL1524', // (15% for first 24 months)
  },
  PUNTO: {
    discount_code: 'REFERRAL1524', // (15% for first 24 months)
  },
  ELASTICPATH: {
    discount_code: 'REFERRAL15', // TODO!!!: need to create (15% forever) - WANTS 20
  },
}

// NOTE: these partner codes here (ie. 'AGNO', 'DEMO') go in as metadata to allow us to not show alertNotification in useStackableApp
const VERSIONED_DISCOUNT_MAPPING = {
  AGNO: {
    coupon_id: 'SPONSORED', // 100% forever
  },
  DEMO: {
    coupon_id: 'SPONSORED', // 100% forever
  },
}

export const VERSIONED_DISCOUNT_CODES = Object.keys(VERSIONED_DISCOUNT_MAPPING)
const PARTNER_DISCOUNT_CODES = Object.keys(PARTNER_DISCOUNT_MAPPING)

// NOTE getMappedDiscountData returns { discountInstrument, metadata }
export const getMappedDiscountData = async (releaseNumber, _discountInstrument) => {
  const discountInstrument = cleanObject(_discountInstrument, false, stringEmptyOnly)
  const { discount_code: _discountCode } = discountInstrument

  const [partnerCode, _releaseNumber] = ensureString(_discountCode).split(PROMO_SEPARATOR)

  let partnerDiscountData
  const validPartnerCode = (PARTNER_DISCOUNT_CODES.includes(partnerCode) || VERSIONED_DISCOUNT_CODES.includes(partnerCode))

  if (validPartnerCode) {
    // eslint-disable-next-line eqeqeq
    if (stringEmpty(_releaseNumber) || (releaseNumber != _releaseNumber)) {
      partnerDiscountData = PARTNER_DISCOUNT_MAPPING[partnerCode]
    } else {
      partnerDiscountData = VERSIONED_DISCOUNT_MAPPING[partnerCode]
    }

    return {
      metadata: {
        [SUBSCRIPTION_METADATA.PARTNER]: partnerCode,
      },
      ...objectNotEmpty(partnerDiscountData) && {
        discountInstrument: partnerDiscountData,
      },
    }
  }

  if (objectEmpty(discountInstrument) || FAUX_DISCOUNT_CODES.includes(_discountCode)) {
    return undefined
  }

  return {
    discountInstrument,
  }
}

// NOTE: intentionally async for future external PRM
export const getValidDeferredVersionedCodes = async (releaseNumber) => {
  if (stringEmpty(releaseNumber)) {
    return []
  }

  return VERSIONED_DISCOUNT_CODES.map((discountCode) => (
    [discountCode, releaseNumber].join(PROMO_SEPARATOR)
  ))
}

// NOTE: intentionally async for future external PRM
export const getValidDeferredCodes = async () => (
  [
    ...FAUX_DISCOUNT_CODES,
    ...PARTNER_DISCOUNT_CODES
  ]
)
