/* eslint-disable no-restricted-syntax, no-undef, symbol-description */
import debug from 'debug'

const singleton = Symbol('singleton')
const singletonEnforcer = Symbol('singletonEnforcer')

const LOG_COLORS = {
  trace: 'pink',
  log: 'purple',
  info: 'blue',
  warn: 'orange',
  error: 'red',
}

export class Logger {
  constructor(enforcer, { prefix, pattern = '*', colors = LOG_COLORS }) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot instantiate Logger directly, please use init()')
    }
    if (!prefix) {
      throw new Error('Cannot instantiate without passing a prefix into init({ prefix })')
    }
    this._type = 'Logger'
    this._consoles = {}

    try {
      if (localStorage) {
        const debugItem = localStorage.getItem('debug')
        if (!debugItem || !debugItem.startsWith(prefix)) {
          localStorage.setItem('debug', `${prefix}:${pattern}`)
        }
        this.modify(prefix, colors)
      }
    // eslint-disable-next-line no-empty
    } catch (ignor) {}
  }

  static init(options) {
    if (!this[singleton]) {
      this[singleton] = new Logger(singletonEnforcer, options)
    }

    return this[singleton]
  }

  reset() {
    if (console) {
      // eslint-disable-next-line guard-for-in
      for (const level in this._consoles) {
        console[level] = this._consoles[level]
      }
    }
  }

  modify(prefix, colors) {
    if (console) {
      for (const level in console) {
        if (Object.keys(colors).includes(level)) {
          this._consoles[level] = console[level]
          const namespace = `${prefix}:${level}`
          const createDebug = debug(namespace)
          createDebug.color = colors[level]
          createDebug.log = console[level].bind(console) // bind to original console method so not caught in infinite loop

          // TODO: call crashlytics/logrocket/sentri.io (firebase analytics w/ app_exception)
          console[level] = (...args) => {
            const message = args[0]
            const source = args[1]
            if (source) {
              createDebug(source, message)
            } else {
              createDebug(message)
            }
          }
        }
      }
    }
  }

  get type() {
    return this._type
  }

  set type(value) {
    this._type = value
  }
}
