/* eslint-disable no-use-before-define */
// import merge from 'deepmerge'
// NOTE: this is a temp fix to work around lack of esm support: https://github.com/TehShrike/deepmerge/issues/250#issuecomment-1609078428
import merge from '@bundled-es-modules/deepmerge'
// const merge = require('deepmerge')
// TODO!!!!!: explore moving to @fastify/deepmerge

const REACT_SYMBOL = Symbol.for('react.element')

// TODO!!!: keep in sync between next-shopify and lib-core
export const deepmerge = merge
// NOTE: deepmerge(x, y) Merge two objects x and y deeply, returning a new merged object with the elements from both x and y. If an element at the same key is present for both x and y, the value from y will appear in the result.

// NOTE: can be used to deeply combine objects in arrays (by index):
/*
deepmerge(
  [{ a: true }],
  [{ b: true }, 'ah yup'],
  { arrayMerge }
) // => [{ a: true, b: true }, 'ah yup']
*/
export const ARRAY_MERGE = {
  REPLACE: (_target, source) => source,
  COMBINE: (target, source, options) => {
    const destination = target.slice()

    source.forEach((item, index) => {
      if (typeof destination[index] === 'undefined') {
        destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
      } else if (options.isMergeableObject(item)) {
        destination[index] = deepmerge(target[index], item, options)
      } else if (target.indexOf(item) === -1) {
        destination.push(item)
      }
    })
    return destination
  },
}

export const extraDeepMerge = (x, y, { arrayMerge = ARRAY_MERGE.COMBINE, ...options } = {}) => (
  deepmerge(x, y, {
    ...options,
    arrayMerge,
  })
)

export const mergeAll = (arrays, options) => {
  switch (true) {
    case arrayEmpty(arrays): {
      return undefined
    }

    case (arrays.length === 1): {
      return arrays[0]
    }

    default: {
      return deepmerge.all(arrays, options)
    }
  }
}

// NOTE: use this in place of mergeAll ONLY if each element in the array is an object and want to return combined object AND there are instances of classes in the object
export const safeMergeAll = (arrays) => {
  switch (true) {
    case arrayEmpty(arrays): {
      return undefined
    }

    case (arrays.length === 1): {
      return arrays[0]
    }

    default: {
      return ensureArray(arrays).reduce((_merged, data) => (
        // eslint-disable-next-line prefer-object-spread
        Object.assign({}, _merged, data)
      ), {})
    }
  }
}

export const ensureAsync = async (result) => {
  if (result instanceof Promise) {
    await result
  }

  return result
}

export const getRequestMethod = (body, _method) => {
  const method = _method ?? (body ? 'POST' : 'GET')

  return uppercase(method)
}

export const normalizeShopifyId = (shopifyData) => {
  // TODO move CART__DRAFT const into lib-core
  if (shopifyData === 'cart_draft') {
    return {
      id: shopifyData,
    }
  }

  const _isString = isString(shopifyData)
  if (_isString && !shopifyData.startsWith('gid:')) {
    if (!isNumericOnly(shopifyData)) {
      return {}
    }

    return {
      id: `${shopifyData}`,
    }
  }

  const { id: _entityId, legacyResourceId } = _isString
    ? { id: shopifyData }
    : ensureObject(shopifyData)

  if (stringEmpty(_entityId) && stringEmpty(legacyResourceId)) {
    return {}
  }

  const entity_id = ensureString(_entityId)
  const { entity_type, parsedId } = entity_id.match(/^gid:\/\/shopify\/(?<entity_type>.*)\/(?<parsedId>[0-9]+).*$/)?.groups ?? {}
  const id = ensureString(parsedId || legacyResourceId)

  const hasId = stringNotEmpty(id)
  const hasEntityId = stringNotEmpty(entity_id)
  const hasEntityType = stringNotEmpty(entity_type)

  return {
    ...stringNotEmpty(id) && { id },
    ...hasEntityType && { entity_type },
    ...hasEntityId ? {
      entity_id,
      gid: entity_id,
    } : (hasEntityType && hasId) && {
      gid: `gid://shopify/${entity_type}/${id}`,
    },
  }
}

export const random = (max = 100, min = 1) => {
  const floor = Math.min(max, min)
  return Math.floor(Math.random() * (max - floor + 1)) + floor
}

export const nextRandom = (max = 100, min = 1) => {
  const maximum = Math.max(max, 0)
  const minimum = Math.max(min, 0)

  let randomNumber = maximum

  if (maximum > minimum) {
    do {
      randomNumber = random(maximum, minimum)
    } while (randomNumber === nextRandom.last)

    nextRandom.last = randomNumber
  }

  return randomNumber
}

export const arrayRandom = (_values, _max = 1) => {
  const values = ensureArray(_values)
  const valuesLength = values.length
  const maxLength = Math.min(_max, valuesLength)

  if (valuesLength <= maxLength) {
    return values
  }

  const arrayLength = valuesLength - 1
  const randomValues = []
  do {
    const newVal = values[nextRandom(arrayLength, 0)]
    if (!randomValues.includes(newVal)) {
      randomValues.push(newVal)
    }
  } while (randomValues.length < maxLength)

  return randomValues
}

export const arrayRandomItem = (_values) => (
  arrayRandom(_values)[0]
)

export const isArray = (value) => (
  // eslint-disable-next-line eqeqeq
  (value != undefined) && Array.isArray(value)
)

export const isSet = (value) => (
  // eslint-disable-next-line eqeqeq
  (value != undefined) && (value instanceof Set)
)

export const isType = (value, type) => (
  // eslint-disable-next-line eqeqeq, valid-typeof
  (value != undefined) && (typeof value === type)
)

export const isString = (value) => (
  isType(value, 'string') ||
  (value instanceof String)
)

const isReact = (value) => (
  isType(value, 'object') &&
  (value?.$$typeof === REACT_SYMBOL)
)

const isClass = (value) => (
  isType(value, 'object') &&
  (Object.getPrototypeOf(value) !== Object.prototype) &&
  !isString(value) &&
  !isReact(value)
)

export const isTypeEnhanced = (value, type) => {
  switch (true) {
    case (type === 'boolean'): {
      return isType(value, type) || ['true', 'false'].includes(value)
    }

    case (type === 'string'): {
      return isString(value)
    }

    case (type === 'set'): {
      return isSet(value)
    }

    case (type === 'react'): {
      return isReact(value)
    }

    case (type === 'class'): {
      return isClass(value)
    }

    case (type === 'array'): {
      return (
        isArray(value) &&
        !isSet(value)
      )
    }

    case (type === 'object'): {
      return (
        isType(value, type) &&
        !isString(value) &&
        !isArray(value) &&
        !isClass(value) &&
        !isReact(value)
      )
    }

    default: {
      return isType(value, type)
    }
  }
}

export const safeTrim = (value) => {
  if (isTypeEnhanced(value, 'string')) {
    // eslint-disable-next-line no-param-reassign
    value = value.trim()
  }

  return value
}

export const parseURL = (url) => {
  try {
    // NOTE: IE not supported
    return new URL(url)
  } catch (ignore) {
    return {}
  }
}

export const isParseable = (value, trim, matchQuoted) => {
  if (!isTypeEnhanced(value, 'string')) {
    return false
  }

  if (trim) {
    // eslint-disable-next-line no-param-reassign
    value = safeTrim(value)
  }

  return isTrue(value.startsWith('{') || value.startsWith('[') || (matchQuoted && value.startsWith('"')))
}

export const safeParse = (value, trim) => {
  switch (true) {
    case isTypeEnhanced(value, 'object'):
    case isTypeEnhanced(value, 'array'): {
      return value
    }

    case isParseable(value, trim, true): {
      if (trim) {
        // eslint-disable-next-line no-param-reassign
        value = safeTrim(value)
      }

      try {
        return JSON.parse(value)
      } catch (_ignore) {
        console.info('Ignoring error parsing', _ignore)
        // TODO: should this be value ?? {}
        return value
      }
    }

    default: {
      // TODO: should this be value ?? {}
      return value
    }
  }
}

export const ensureString = (string) => (
  string ? `${string}` : ''
)

// HMMM: what if 'string' is an object?
export const ensureStringOnly = (string) => (
  // eslint-disable-next-line eqeqeq
  (string != undefined) ? `${string}` : ''
)

export const ensureStringAscii = (string) => (
  // eslint-disable-next-line no-control-regex
  ensureStringOnly(string).replace(/[^\x00-\x7F]/g, '')
)

export const ensureStringClean = (string) => (
  // eslint-disable-next-line no-control-regex
  ensureStringOnly(string).replace(/[^a-z0-9_-]/gi, '')
)

export const ensureAlphaNumeric = (string) => (
  // eslint-disable-next-line no-control-regex
  ensureStringOnly(string).replace(/[^a-z0-9]/gi, '')
)

export const isNumericOnly = (value) => {
  const [matched] = `${value}`.match(/^([0-9]+)$/) ?? []
  // eslint-disable-next-line eqeqeq
  return (matched != undefined)
}

export const isNumericNegatable = (value) => {
  const [matched] = `${value}`.match(/^(-?[0-9]+(\.[0-9]+)?)?$/) ?? []
  // eslint-disable-next-line eqeqeq
  return (matched != undefined)
}

// TODO: explore places using ensureNumeric to move to isNumericNegatable
export const ensureNumeric = (string) => (
  Number(ensureString(string).replace(/[^0-9.]/gi, ''))
)

export const ensureNumericOnly = (string) => (
  Number(ensureString(string).replace(/[^0-9]/gi, ''))
)

// TODO: update regex to handle negative number returns negative. Something like (?!\d\.)(-?\d+(\.\d)?)
export const ensureNumericNegatable = (string) => (
  Number(ensureString(string).replace(/[^0-9.-]/gi, ''))
)

export const ensureNumericConstrained = (value, { min: _min = 0, max: _max = 100 } = {}) => {
  const min = ensureNumericNegatable(_min)
  const max = ensureNumericNegatable(_max)

  return Math.max(
    min,
    Math.min(ensureNumeric(value), max)
  )
}

export const ensureArray = (array = []) => (
  // eslint-disable-next-line no-nested-ternary
  !array ? [] : Array.isArray(array) ? array : [array]
)

export const ensureArraySet = (arrayOrSet) => (
  ensureArray(isSet(arrayOrSet) ? [...arrayOrSet] : arrayOrSet)
)

export const arrayEmpty = (array, disableEmptyString = false) => (
  // eslint-disable-next-line eqeqeq
  !array || !array.length || ((array.length === 1) && ((array[0] == undefined) || (disableEmptyString && stringEmpty(array[0]))))
)

export const arrayNotEmpty = (array, disableEmptyString = false) => (
  // eslint-disable-next-line eqeqeq
  !arrayEmpty(array) && array[0] != undefined && (!disableEmptyString || stringNotEmpty(array[0]))
)

export const findLastMatch = (array, filterCallback = (value) => (value)) => (
  ensureArray(array).filter(filterCallback).slice(-1)[0]
)

export const ensureObject = (object) => (
  object ?? {}
)

// NOTE: this does not ensure !isType(string)
export const objectEmpty = (object) => (
  !object || !Object.keys(object).length
)

export const objectNotEmpty = (object) => (
  !objectEmpty(object)
)

export const nullable = (object) => (
  (!object || (object === 'null') || (object === undefined) || (object === null))
    ? null // TODO: explore undefined here??
    : object
)

export const objectContainsAnyValue = (object) => (
  Object.values(ensureObject(object)).some((value) => nullable(value))
)

const isUndefined = (value) => (
  // eslint-disable-next-line eqeqeq
  value == undefined
)

export const cleanObjectKeys = (object, _removeKeyNames) => {
  const removeKeyNames = ensureArray(_removeKeyNames)
  return Object.fromEntries(
    Object.entries(ensureObject(object)).filter(([key]) => !removeKeyNames.includes(key))
  )
}

export const cleanObject = (object, allowEmptyLeafs, isEmptyCallback) => (
  Object.entries(ensureObject(object)).reduce((
    _cleanedObject,
    [key, _value]
  ) => {
    if (!allowEmptyLeafs && (isEmptyCallback?.(_value) ?? isUndefined(_value))) {
      return _cleanedObject // skip key if null or undefined
    }

    const value = !Array.isArray(_value)
      ? cleanObjectValue(_value, allowEmptyLeafs, isEmptyCallback)
      : _value.reduce((_data, __value) => ([
        ..._data,
        cleanObjectValue(__value, allowEmptyLeafs, isEmptyCallback)
      ]), [])

    if (!allowEmptyLeafs && (isEmptyCallback?.(value) ?? isUndefined(value))) {
      return _cleanedObject // skip key if null or undefined
    }

    return {
      ..._cleanedObject,
      [key]: value,
    }
  }, {})
)

const cleanObjectValue = (value, allowEmptyLeafs, isEmptyCallback) => {
  switch (true) {
    case isClass(value): {
      return undefined
    }

    case isTypeEnhanced(value, 'object'): {
      return cleanObject(value, allowEmptyLeafs, isEmptyCallback)
    }

    default: {
      return value
    }
  }
}

export const stringNotEmptyOnly = (stringable) => {
  const string = ensureStringOnly(stringable)
  return ((string.length > 0) && !['null', 'undefined'].includes(string))
}

export const stringEmptyOnly = (stringable) => (
  !stringNotEmptyOnly(stringable)
)

export const stringNotEmpty = (stringable) => {
  const string = ensureString(stringable)
  return ((string.length > 0) && !['null', 'undefined'].includes(string))
}

export const stringEmpty = (stringable) => (
  !stringNotEmpty(stringable)
)

const splitString = (splitting, index = splitting.length) => {
  const string = ensureString(splitting)
  return [string.slice(0, index), string.slice(index)]
}

export const compareNumber = (number1 = 0, number2 = 0) => (
  number1 - number2
)

export const compareString = (string1, string2) => {
  if (stringEmpty(string1)) {
    return 1
  }

  if (stringEmpty(string2)) {
    return -1
  }

  return ensureString(string1).localeCompare(string2)
}

export const compareEntryKeys = ([string1], [string2]) => (
  compareString(string1, string2)
)

export const objectToSortedString = (object, separator = '') => (
  Object.entries(ensureObject(object))
    .sort(compareEntryKeys)
    .map(([key, value]) => `${key}=${value}`)
    .join(separator)
)

export const isHTML = (stringable, trim = false) => {
  const string = trim ? ensureString(stringable).trim() : stringable
  return (string?.startsWith?.('<') || string?.startsWith?.('\n<')) ?? false
}

export const cloneObject = (object) => (
  JSON.parse(JSON.stringify(object))
)

export const freeze = (logMessage, object, type = 'log') => {
  console[type](logMessage, !object ? object : cloneObject(ensureObject(object)))
}

export const replaceSpaces = (string, replacement = '') => (
  !isType(string, 'string')
    ? ''
    : string.replace(/\s/g, replacement)
)

const recase = (string, replacement = '') => (
  !isType(string, 'string')
    ? ''
    : string
      .replace(/[^a-zA-Z0-9]+/g, replacement)
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/([0-9])([^0-9])/g, '$1-$2')
      .replace(/([^0-9])([0-9])/g, '$1-$2')
      .replace(/[-_]+/g, replacement)
      .replace(new RegExp(`${replacement}$`), '')
      .toLowerCase()
)

export const zencase = (string, replacement = '-') => (
  !isType(string, 'string')
    ? ''
    : replaceSpaces(string, replacement)
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(new RegExp(`${replacement}$`), '')
      .toLowerCase()
)

export const dashcase = (string) => (
  recase(string, '-')
)

export const undashcase = (string, replacement = ' ') => (
  ensureString(string).replace(/-/g, replacement).trim()
)

export const snakecase = (string) => (
  recase(string, '_')
)

export const unsnakecase = (string, replacement = ' ') => (
  ensureString(string).replace(/_/g, replacement).trim()
)

export const uppercase = (string) => (
  ensureString(string).toUpperCase()
)

export const lowercase = (string, defaultValue = '') => (
  !stringNotEmpty(string)
    ? defaultValue
    : ensureString(string).toLowerCase()
)

export const isLowercase = (string) => (
  stringNotEmpty(string) && /^[^A-Z]*$/.test(string)
)

export const slugify = (string) => (
  lowercase(dashcase(string))
)

export const capitalize = (string) => {
  const parts = splitString(string, 1)
  return `${uppercase(parts[0])}${parts[1]}`
}

export const camelCase = (string) => (
  stringEmpty(string)
    ? ''
    : unsnakecase(undashcase(string)).replace(/\w\S*/g, (word, charIndex) => {
      if (charIndex === 0) {
        return lowercase(word)
      }
      return `${uppercase(word.charAt(0))}${lowercase(word.substr(1))}`
    }).split(' ').join('')
)

export const formatCarrier = (string) => {
  if (stringEmpty(string)) {
    return undefined
  }

  const overrides = {
    stamps_com: 'Stamps.com',
  }

  return uppercase(overrides[lowercase(string)] ?? unsnakecase(string))
}

export const titleCase = (_string, _overrides) => {
  if (stringEmpty(_string)) {
    return ''
  }

  const overrides = {
    'add-ons': 'Add-Ons',
    agnostack: 'agnoStack',
    ai: 'AI',
    'ai for commerce': 'AI for Commerce',
    "'ai for commerce'": "'AI For Commerce'",
    b2b: 'B2B',
    b2c: 'B2C',
    cartcollab: 'CartCollab',
    'cartcollab(sm)': 'CartCollab(SM)',
    chatgpt: 'ChatGPT',
    covid: 'COVID',
    covid19: 'COVID-19',
    'covid-19': 'COVID-19',
    crm: 'CRM',
    elasticpath: 'Elastic Path',
    ecommerce: 'eCommerce',
    'e-commerce': 'eCommerce',
    faqs: 'FAQs',
    gpt: 'GPT',
    ipaas: 'iPaaS',
    'ltv.ai': 'LTV.ai',
    'smile.io': 'Smile.io',
    'stamped.io': 'Stamped.io',
    'judge.me': 'Judge.me',
    'influence.io': 'Influence.io',
    keepsmallstrong: 'KeepSmallStrong',
    loyaltylion: 'LoyaltyLion',
    'mach alliance': 'MACH Alliance',
    openai: 'OpenAI',
    paypal: 'PayPal',
    'postscript.io': 'Postscript.io',
    recharge: 'Recharge',
    'stay.ai': 'Stay Ai',
    'stay ai': 'Stay Ai',
    shipengine: 'ShipEngine',
    shipperhq: 'ShipperHQ',
    shipstation: 'ShipStation',
    taxjar: 'TaxJar',
    vs: 'vs',
    'vs.': 'vs.',
    yotpo: 'YotPo',
    "zendesk 'ai for commerce'": "Zendesk 'AI For Commerce'",
    ..._overrides,
  }

  const string = lowercase(_string)

  if (overrides[string]) {
    return overrides[string]
  }

  const stringParts = string
    .split(' ')
    .reduce((
      _stringParts,
      _stringPart
    ) => {
      const stringPart = overrides[_stringPart] ?? _stringPart
        .replace(/([A-Z]+)/g, ' $1')
        .replace(/\s\s+/g, ' ')
        .replace(/(\b[a-z](?!\s)*)/g, (firstChar) => uppercase(firstChar))

      return [
        ..._stringParts,
        ...stringNotEmpty(stringPart) ? [stringPart] : []
      ]
    }, [])

  return stringParts.join(' ')
}

export const removeLeadingDollar = (string) => (
  ensureString(string).replace(/^\$/, '')
)

export const ensureLeadingDollar = (string) => (
  `$${removeLeadingSlash(string)}`
)

export const removeLeadingSlash = (string) => (
  ensureString(string).replace(/^\//, '')
)

export const removeLeadingTrailingQuotes = (string) => (
  ensureString(string).replace(/^"|"$/g, '')
)

// TODO: not sure if this should remove multipel at end or just one (as it does now)?
export const removeTrailingSlash = (string) => (
  ensureString(string).replace(/\/$/, '')
)

export const removeLeadingTrailingSlash = (string) => (
  removeTrailingSlash(removeLeadingSlash(string))
)

export const ensureLeadingSlash = (string) => (
  `/${removeLeadingSlash(string)}`
)

export const ensureTrailingSlash = (string) => (
  `${removeTrailingSlash(string)}/`
)

export const ensureTrailingQuestion = (string) => {
  if (!isString(string)) {
    return string
  }
  return string.endsWith('?') ? string : `${string}?`
}

export const ensureExtension = (string, extension) => {
  if (stringEmpty(extension)) {
    return string
  }

  const filePath = string.match(/(.*)\..+$/)?.[1] ?? string

  return `${filePath}.${extension}`
}

export const wrappedEncode = (basePath, _wrappingPath) => {
  const wrappingPath = ensureString(_wrappingPath)
  if (stringEmpty(basePath)) {
    return wrappingPath
  }

  return `${basePath}${encodeURIComponent(wrappingPath)}`
}

export const splitCommas = (value) => (
  ensureString(value)
    .replace(/, /g, ',')
    .split(',')
    .reduce((_values, _value) => {
      const __value = safeTrim(_value)
      return [
        ..._values,
        ...stringNotEmpty(__value) ? [__value] : [] // TODO: explore stringEmptyOnly?
      ]
    }, [])
)

export const combineCommas = (values) => (
  [...new Set(
    ensureArray(values).reduce((
      _combined,
      value
    ) => ([
      ..._combined,
      ...splitCommas(value)
    ]), [])
  )]
)

export const normalizeArray = (input, separator = ' ') => {
  const inputArray = ensureArray(input)

  return inputArray.reduce((normalized, _ignore, index) => ([
    ...normalized,
    inputArray.slice(0, index + 1).join(separator)
  ]), []).reverse()
}

export const arrayToObject = (arrayable, key) => (
  ensureArray(arrayable).reduce((_object, item) => ({
    ..._object,
    [item?.[key]]: item,
  }), {})
)

export const isTrue = (value, falsy) => {
  const string = ensureString(value)
  return ![...ensureArray(falsy), '', 'false'].includes(string)
}

export const isUndefinedOrTrue = (value, falsy) => (
  isUndefined(value) || isTrue(value, falsy)
)

export const isBoolean = (value) => (
  [true, false, 'true', 'false'].includes(value)
)

export const getBaseDomain = (baseUrl) => (
  baseUrl?.match(/^(?:https?:\/\/)?([^/:]+)(?::(\d+))?/).slice(1, 3)
    .filter(stringNotEmpty)
    .join(':') || undefined
)

// TODO: move all uses of parseKeywords to parseKeywordGroups
export const parseKeywords = (keywordGroup, previous) => (
  [...new Set(ensureArray(keywordGroup).reduce((_parsedKeywords, keyword) => {
    const keywords = splitCommas(keyword)

    return [
      ..._parsedKeywords,
      ...keywords.filter((_keyword) => stringNotEmpty(_keyword))
    ]
  }, ensureArray(previous)))].join(', ')
)

export const parseCommaGroups = (commaGroups) => {
  const parsedGroups = ensureArray(commaGroups).reduce((_parsedGroups, commaGroup) => ([
    ..._parsedGroups,
    ...ensureArray(commaGroup).reduce((_parsedCommaKeywords, keyword) => {
      const keywords = splitCommas(keyword)

      return [
        ..._parsedCommaKeywords,
        ...keywords.filter((_keyword) => stringNotEmpty(_keyword))
      ]
    }, [])
  ]), [])

  return [...new Set(parsedGroups)]
}

export const parseKeywordGroups = (keywordGroups) => (
  parseCommaGroups(keywordGroups).join(', ')
)

export const parseProjectName = (_name) => {
  const name = ensureString(_name)
  const [, organization, ...parts] = /(@.*)([\\]+|\/)+(.*)/.exec(name) ?? []
  const projectName = organization ? name.replace(organization, '').replace('/', '') : name

  return {
    name,
    parts,
    organization,
    projectName,
  }
}

export const parseProject = (packageInfo) => {
  const {
    version,
    keywords,
    shortName,
    siteHandle,
    siteAuthor,
    devDependencies,
    peerDependencies,
    bundleDependencies,
    bundledDependencies = bundleDependencies,
    dependencies: runtimeDependencies,
    name: _name,
    owner: _appOwner,
    appName: _appName,
    siteName: _siteName,
  } = packageInfo ?? {}
  const { name, organization, projectName } = parseProjectName(_name)

  const appName = _appName ?? projectName?.slice(projectName?.indexOf('-') + 1)
  const siteName = _siteName ?? projectName?.slice(projectName?.indexOf('-') + 1)
  const companyName = titleCase(organization?.replace('@', ''))
  const appOwner = _appOwner ?? companyName

  return [{
    keywords,
    appOwner,
    appName,
    version,
    siteName,
    shortName,
    siteHandle,
    siteAuthor,
    companyName,
    projectName,
    devDependencies,
    peerDependencies,
    bundledDependencies,
    runtimeDependencies,
  }, organization, name]
}
