import { ensureObject, ensureString, lowercase, stringNotEmpty } from './display'

export const translators = {
  ar: async () => (await import('../translations/ar.json')).default,
  bg: async () => (await import('../translations/bg.json')).default,
  cs: async () => (await import('../translations/cs.json')).default,
  da: async () => (await import('../translations/da.json')).default,
  de: async () => (await import('../translations/de.json')).default,
  el: async () => (await import('../translations/el.json')).default,
  en: async () => (await import('../translations/en.json')).default,
  es: async () => (await import('../translations/es.json')).default,
  fi: async () => (await import('../translations/fi.json')).default,
  fr: async () => (await import('../translations/fr.json')).default,
  he: async () => (await import('../translations/he.json')).default,
  hi: async () => (await import('../translations/hi.json')).default,
  hu: async () => (await import('../translations/hu.json')).default,
  id: async () => (await import('../translations/id.json')).default,
  it: async () => (await import('../translations/it.json')).default,
  ja: async () => (await import('../translations/ja.json')).default,
  ko: async () => (await import('../translations/ko.json')).default,
  nl: async () => (await import('../translations/nl.json')).default,
  no: async () => (await import('../translations/no.json')).default,
  pl: async () => (await import('../translations/pl.json')).default,
  pt: async () => (await import('../translations/pt.json')).default,
  ro: async () => (await import('../translations/ro.json')).default,
  ru: async () => (await import('../translations/ru.json')).default,
  sv: async () => (await import('../translations/sv.json')).default,
  th: async () => (await import('../translations/th.json')).default,
  tr: async () => (await import('../translations/tr.json')).default,
  uk: async () => (await import('../translations/uk.json')).default,
  vi: async () => (await import('../translations/vi.json')).default,
  zh: async () => (await import('../translations/zh-cn.json')).default,
  'zh-cn': async () => (await import('../translations/zh-cn.json')).default,
  'zh-tw': async () => (await import('../translations/zh-tw.json')).default,
}

// NOTE: needed for package export??

export const splitLocale = (locale) => {
  const [_country, _code] = lowercase(locale).replace(/_/g, '-').split('-')
  return [ensureString(_country), ensureString(_code)]
}

const mapLocale = (locale, _locales) => {
  const [country, code] = splitLocale(locale)

  return _locales[`${country}-${code}`] ?? _locales[country]
}

export const initializeTranslator = ({ translators: _translators }) => async (_locale) => {
  try {
    const __translators = ensureObject(_translators)

    const locales = Object.keys(__translators).reduce((_locales, translator) => ({
      ..._locales,
      [translator]: translator,
    }), {})

    const locale = mapLocale(_locale, locales)
    if (stringNotEmpty(locale)) {
      return __translators[locale]?.()
    }

    return await Object.entries(__translators).reduce(async (_translated, [tLocale, translator]) => ({
      ..._translated,
      [tLocale]: await translator(),
    }))
  } catch (_ignore) {
    return undefined
  }
}

export const getTranslated = initializeTranslator({ translators })
