import Mustache from 'mustache'

// TODO: rename color files to lowercase
import AmazonColors from '../data/colors/Amazon.json'
import BigCommerceColors from '../data/colors/BigCommerce.json'
import CommerceToolsColors from '../data/colors/CommerceTools.json'
import OrdergrooveColors from '../data/colors/Ordergroove.json'
import MagentoColors from '../data/colors/Magento.json'
import MoltinColors from '../data/colors/Moltin.json'
import PayWhirlColors from '../data/colors/PayWhirl.json'
import RechargeColors from '../data/colors/Recharge.json'
import ShopifyColors from '../data/colors/Shopify.json'
import StayAIColors from '../data/colors/StayAI.json'
import StripeColors from '../data/colors/Stripe.json'
import WooCommerceColors from '../data/colors/WooCommerce.json'
import {
  ensureString,
  ensureObject,
  cleanObject,
  dashcase,
  deepmerge,
  ensureArray,
} from './display'
import { DEFAULT_LOCALE } from './constants'

export { default as manifestTemplate } from '../data/manifest.json'
export { default as trackingConfigData } from '../data/tracking-config.json'

export const data = {
  agentData: async () => (await import('../data/agent.json')).default,
  pricingData: async () => (await import('../data/pricing.json')).default,
  replacementsData: async () => (await import('../data/replacements.json')).default,
  searchAgentsData: async () => (await import('../data/search-agents.json')).default,
  ticketPlaceholdersData: async () => (await import('../data/ticket-placeholders.json')).default,
  ticketData: async () => (await import('../data/ticket.json')).default,
  ticketCommentsData: async () => (await import('../data/ticket-comments.json')).default,
  ticketsData: async () => (await import('../data/tickets.json')).default,
  ticketsAuditsData: async () => (await import('../data/tickets-audits.json')).default,
  ticketsViewsData: async () => (await import('../data/tickets-views.json')).default,
}

export const getData = async (key, _data) => {
  try {
    const _asyncData = await data[key]?.()
    // eslint-disable-next-line eqeqeq
    if (_data == undefined) {
      return _asyncData
    }

    if (Array.isArray(_asyncData)) {
      return [
        ..._asyncData,
        ...ensureArray(_data)
      ]
    }

    return {
      ..._asyncData,
      ..._data,
    }
  } catch (_ignore) {
    return undefined
  }
}

export const getReplacedAccountData = async (__replacements) => {
  const _replacements = ensureObject(__replacements)
  // HMMM: does this need to be exported somewhere to be bundled separate??
  const template = (await import('../data/account.json')).default

  const {
    account: {
      subdomain,
      url: _url,
      ..._account
    } = {},
  } = _replacements

  const replacements = {
    ..._replacements,
    account: {
      ..._account,
      subdomain,
      ...(_url || subdomain) && {
        url: _url ?? `${subdomain}.zendesk.com`,
      },
    },
  }

  return deepmerge(
    template,
    // TODO: explore if need to call cleanObject(xyz, false, stringEmptyOnly)
    cleanObject(replacements)
  )
}

export const getInstancesData = async (__replacements) => {
  const _replacements = ensureObject(__replacements)
  // HMMM: does this need to be exported somewhere to be bundled separate??
  const template = (await import('../data/instances.json')).default

  const {
    account: {
      subdomain,
      url: _url,
      ..._account
    } = {},
  } = _replacements

  const replacements = {
    ..._replacements,
    account: {
      ..._account,
      subdomain,
      ...(_url || subdomain) && {
        url: _url ?? `${subdomain}.zendesk.com`,
      },
    },
  }

  const instances = Object.entries(template.instances).reduce((_instances, [instanceId, instance]) => ({
    ..._instances,
    [instanceId]: deepmerge(
      instance,
      // TODO: explore if need to call cleanObject(xyz, false, stringEmptyOnly)
      cleanObject(replacements)
    ),
  }), {})

  return { instances }
}

export const getManifestReplacements = (replacements) => (
  // TODO: explore if need to call cleanObject(xyz, false, stringEmptyOnly)
  cleanObject(
    deepmerge({
      locale: DEFAULT_LOCALE,
      owner: 'agnoStack',
      email: 'info@agnostack.com',
      url: 'https://agnostack.com',
      url_terms: 'https://agnostack.com/terms',
      // eslint-disable-next-line max-len
      url_feedback: 'https://docs.google.com/forms/d/e/1FAIpQLSdbHyhhFmSn5Xahck3D02f5QqHt4u6nTbsBBA8GlCSY5X3iKw/viewform?usp=pp_url&entry.536218629=',
      parameter_type: 'hidden',
      ...replacements?.version && {
        dash_version: dashcase(replacements.version),
      },
    // TODO: explore if need to call cleanObject(xyz, false, stringEmptyOnly)
    }, cleanObject(replacements))
  )
)

export const templateReplace = (template, replacements, partials, tokens) => {
  // NOTE: fixes issues where by default standard characters are HTML encoded (https://stackoverflow.com/questions/22910428/mustache-globally-disable-html-escaping)
  Mustache.escape = (value) => (value)

  return Mustache.render(ensureString(template), ensureObject(replacements), partials, tokens)
}

// TODO: rename color files to lowercase
export const COLORS = {
  AMAZON: AmazonColors,
  BIGCOMMERCE: BigCommerceColors,
  COMMERCETOOLS: CommerceToolsColors,
  MAGENTO: MagentoColors,
  ELASTICPATH: MoltinColors, // TODO!!!: rename/remove Moltin here (AND fix these colors to match EP brand)
  PAYWHIRL: PayWhirlColors,
  SHOPIFY: ShopifyColors,
  STAYAI: StayAIColors,
  ORDERGROOVE: OrdergrooveColors,
  RECHARGE: RechargeColors,
  STRIPE: StripeColors,
  WOOCOMMERCE: WooCommerceColors,
}
