const SIDEBAR_TICKET = 'ticket_sidebar'
const SIDEBAR_NEW_TICKET = 'new_ticket_sidebar'
const SIDEBAR_ORGANIZATION = 'organization_sidebar'
const SIDEBAR_USER = 'user_sidebar'
const EDITOR = 'ticket_editor'
const MODAL = 'modal'
const TOP = 'top_bar'
const FULLSCREEN = 'nav_bar'
const BACKGROUND = 'background'

export const ROUTABLE_LOCATIONS_EXTENDED = {
  SIDEBAR_TICKET,
  SIDEBAR_NEW_TICKET,
  SIDEBAR_ORGANIZATION,
  SIDEBAR_USER,
  EDITOR,
  MODAL,
  TOP,
}

const ROUTABLE_LOCATIONS_CORE = {
  FULLSCREEN,
}

export const ROUTABLE_LOCATIONS_NEWABLE = {
  CUSTOM_OBJECT: 'custom_object',
  ORGANIZATION: 'organization',
  TICKET: 'ticket',
  USER: 'user',
}

const ROUTABLE_LOCATIONS_PLATFORM = {
  ...ROUTABLE_LOCATIONS_NEWABLE,
  VIEW: 'view',
}

export const ROUTABLE_LOCATIONS = {
  ...ROUTABLE_LOCATIONS_EXTENDED,
  ...ROUTABLE_LOCATIONS_PLATFORM,
  ...ROUTABLE_LOCATIONS_CORE,
}

export const ROUTABLE_ROUTES_PLATFORM = Object.values(ROUTABLE_LOCATIONS_PLATFORM)
export const ROUTABLE_ROUTES = Object.values(ROUTABLE_LOCATIONS)

export const LOCATIONS = {
  ...ROUTABLE_LOCATIONS_EXTENDED,
  ...ROUTABLE_LOCATIONS_CORE,
  BACKGROUND,
}

const MAX_HEIGHTS = {
  DEFAULT: 1280,
  EXPANDED: 274,
  [EDITOR]: 36,
  [TOP]: 485,
}

const MIN_HEIGHTS = {
  DEFAULT: 200,
  MINIMAL: 36,
  EXPANDED: 274,
  [MODAL]: 300,
  [TOP]: 265,
}

const MAX_WIDTHS = {
  DEFAULT: 1920,
}

const MIN_WIDTHS = {
  DEFAULT: 320,
  MINIMAL: 36,
  [EDITOR]: 220,
  [MODAL]: 550,
  [TOP]: 300,
}

const MENU_WIDTHS = {
  [EDITOR]: 450,
}

const isSidebar = (location) => (
  [
    SIDEBAR_USER,
    SIDEBAR_TICKET,
    SIDEBAR_NEW_TICKET,
    SIDEBAR_ORGANIZATION
  ].includes(location)
)

// const isActionable = (location) => (
//   isSidebar(location) && (location !== SIDEBAR_NEW_TICKET)
// )

const isTicket = (location) => (
  [SIDEBAR_TICKET, SIDEBAR_NEW_TICKET].includes(location)
)

const isMinimal = (location) => (
  location === EDITOR
)

const isTop = (location) => (
  location === TOP
)

const isModal = (location) => (
  location === MODAL
)

const isFullscreen = (location) => (
  location === FULLSCREEN
)

const isEditor = (location) => (
  location === EDITOR
)

const isFlexible = (location) => (
  [SIDEBAR_TICKET, SIDEBAR_NEW_TICKET].includes(location)
)

const isRoutable = (routable) => (
  ROUTABLE_ROUTES.includes(routable)
)

const isResizable = (location) => (
  location && !isFullscreen(location)
)

const hasUser = (location) => (
  location === SIDEBAR_USER
)

const hasTicket = (location) => (
  isTicket(location) || isEditor(location)
)

// TODO consider renaming to not confuse with LOCATIONS??
export const locations = {
  isSidebar,
  isModal,
  isTicket,
  isTop,
  isFullscreen,
  isMinimal,
  isEditor,
  isFlexible,
  isRoutable,
  isResizable,
  hasUser,
  hasTicket,
  MAX_HEIGHTS,
  MIN_HEIGHTS,
  MAX_WIDTHS,
  MIN_WIDTHS,
  MENU_WIDTHS,
}
