import {
  ensureArray,
  stringEmpty,
  lowercase,
} from './display'

export const normalizePhoneNumber = async (phoneString, options) => {
  if (stringEmpty(phoneString)) {
    return phoneString
  }

  // eslint-disable-next-line import/no-unresolved
  const { phone } = await import('phone').catch((_ignore) => {
    console.log(`Ignoring optional dependency: phone`, _ignore)
    return {}
  })

  // eslint-disable-next-line eqeqeq
  if (phone == undefined) {
    return phoneString
  }

  return phone(phoneString, options)?.phoneNumber
}

// TODO: can we bundle split to only load config for a specific country?
// TODO: rename to getCountryConfigs
export const getAddressFormats = async () => (
  // eslint-disable-next-line import/no-unresolved
  (await import('../data/generated/country-config.json')).default
)

export const getLanguages = async (_country) => {
  const addressFormats = await getAddressFormats()
  const country = lowercase(_country)

  const countryConfig = addressFormats.find(({ code }) => (
    lowercase(code) === country
  ))

  return ensureArray(countryConfig?.languages).map((language) => (
    lowercase(language)
  ))
}

export const getStateAbbreviations = async (_country = 'US') => {
  const addressFormats = await getAddressFormats()
  const country = lowercase(_country)

  const countryConfig = addressFormats.find(({ code }) => (
    lowercase(code) === country
  ))

  return ensureArray(countryConfig?.states).map(({ value, code }) => ({
    value,
    label: code,
  }))
}

export const abbreviateCountry = async (country) => {
  const addressFormats = await getAddressFormats()
  const _country = lowercase(country)

  return (_country.length > 2)
    ? addressFormats.find(({ name }) => (lowercase(name) === _country))?.code
    : country
}

export const unabbreviateCountry = async (country) => {
  const addressFormats = await getAddressFormats()
  const _country = lowercase(country)

  return (_country.length === 2)
    ? addressFormats.find(({ code }) => (lowercase(code) === _country))?.name
    : country
}

export const abbreviateState = async (state, country = 'US') => {
  const stateAbbreviations = await getStateAbbreviations(country)
  const _state = lowercase(state)

  return (_state.length > 2)
    ? stateAbbreviations.find(({ value }) => (lowercase(value) === _state))?.label
    : state
}

export const unabbreviateState = async (state, country = 'US') => {
  const stateAbbreviations = await getStateAbbreviations(country)
  const _state = lowercase(state)

  return (_state.length === 2)
    ? stateAbbreviations.find(({ label }) => (lowercase(label) === _state))?.value
    : state
}
